import { MILES_WEB_S3_BASE_URL_V2 } from './base';

export const home_page_assets = {
  pre_footer_img: `${MILES_WEB_S3_BASE_URL_V2}footer/v2_pre_footer_largest%402x.png`,
  logo_fb_img: require('src/assets/Miles/footer/logo_fb@2x.png'),
  logo_ins_img: require('src/assets/Miles/footer/logo_ins@2x.png'),
  logo_linkedin_img: require('src/assets/Miles/footer/logo_linkedin@2x.png'),
  logo_twitter_img: require('src/assets/Miles/footer/logo_twitter@2x.png'),
  logo_x_img: require('src/assets/Miles/footer/logo_x@2x.png'),
  miles_logo_white_img: require('src/assets/Miles/footer/miles_logo_white@2x.png'),
  brands_you_love_img: require('src/assets/Miles/brands_you_love@2x.png'),
  brands_you_love_v3_2500_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/brands_you_love_v3/1440_x/brands%402x.png`,
  brands_you_love_v3_1280_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/brands_you_love_v3/1440_x/brands%402x.png`,
  brands_you_love_v3_1000_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/brands_you_love_v3/768_x_1150/Group+120%402x.png`,
  brands_you_love_v3_600_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/brands_you_love_v3/360_x_768/brands%402x.png`,
  brands_you_love_v3_360_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/brands_you_love_v3/+x_360/brands%402x.png`,
  brands_you_love_v4_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/brands_you_love_v3/brands_you_love_v4%402x.png`,
  growing_fast_01_img: require('src/assets/Miles/homePage/growing_fast_01@2x.png'),
  growing_fast_02_img: require('src/assets/Miles/homePage/growing_fast_02@2x.png'),
  growing_fast_03_img: require('src/assets/Miles/homePage/growing_fast_03@2x.png'),
  growing_fast_04_img: require('src/assets/Miles/homePage/growing_fast_04@2x.png'),
  home_hero_01_img: require('src/assets/Miles/homePage/home_hero_01@2x.png'),
  we_care_your_privacy_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/we_care_your_privacy%402x.png`,
  carousel_raffle_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/carousel_raffle%402x.png`,
  carousel_giftcard_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/carousel_giftcard%402x.png`,
  carousel_donation_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/carousel_donation%402x.png`,
  carousel_challenges_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/carousel_challenges%402x.png`,
  active_raffle_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/active_raffle%402x.png`,
  active_giftcard_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/active_giftcard%402x.png`,
  active_donation_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/active_donation%402x.png`,
  active_challenges_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/active_challenges%402x.png`,
  fade_raffle_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/fade_raffle%402x.png`,
  fade_Challenges_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/fade_Challenges%402x.png`,
  fade_gift_card_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/fade_gift_card%402x.png`,
  fade_Donation_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/fade_Donation%402x.png`,
  // section: hero_01 - start
  hero_1_1440: `${MILES_WEB_S3_BASE_URL_V2}home_page/home_hero_image_assets/hero_1_1440%402x.png`,
  hero_1: `${MILES_WEB_S3_BASE_URL_V2}home_page/home_hero_image_assets/hero_1_1280%402x.png`,
  hero_1_768_to_1150: `${MILES_WEB_S3_BASE_URL_V2}home_page/home_hero_image_assets/hero_1_1000%402x.png`,
  hero_1_600_ipad_mini: `${MILES_WEB_S3_BASE_URL_V2}home_page/home_hero_image_assets/hero_1_600%402x.png`,
  // section: hero_01 - end
  // section: hero_02 - start
  trips_modes_visits_icons_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/hero_02/trips_modes_visits_icons%402x.png`,
  icon_green_train_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/hero_02/icon_green_train%402x.png`,
  icon_blue_car_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/hero_02/icon_blue_car%402x.png`,
  icon_yellow_car_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/hero_02/icon_yellow_car%402x.png`,
  icon_blue_airplan_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/hero_02/icon_blue_airplan%402x.png`,
  icon_green_ship_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/hero_02/icon_green_ship%402x.png`,
  icon_walk_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/hero_02/icon_walk%402x.png`,
  icon_run_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/hero_02/icon_run%402x.png`,
  icon_ski_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/hero_02/icon_ski%402x.png`,
  hero_2_1440: `${MILES_WEB_S3_BASE_URL_V2}home_page/home_hero_image_assets/hero_2_2500%402x.png`,
  hero_2: `${MILES_WEB_S3_BASE_URL_V2}home_page/home_hero_image_assets/hero_2_1280%402x.png`,
  hero_2_768_to_1150: `${MILES_WEB_S3_BASE_URL_V2}home_page/home_hero_image_assets/hero_2_1000%402x.png`,
  hero_2_600_ipad_mini: `${MILES_WEB_S3_BASE_URL_V2}home_page/home_hero_image_assets/hero_2_600%402x.png`,
  hero_2_mobile: `${MILES_WEB_S3_BASE_URL_V2}home_page/home_hero_image_assets/hero_2_mobile%402x.png`,
  home_page_hero_02_v2: `${MILES_WEB_S3_BASE_URL_V2}home_page/home_hero_image_assets/home_page_hero_02_v3%402x.png`,
  // section: hero_02 - end
  // section: hero_03 - start
  redeem_miles_icon_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/hero_03/redeem_miles_icon%402x.png`,
  redeem_miles_icon_v2_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/hero_03/redeem_miles_icon_v2%402x.png`,
  hero_3_1440: `${MILES_WEB_S3_BASE_URL_V2}home_page/home_hero_image_assets/hero_3_2500%402x.png`,
  hero_3: `${MILES_WEB_S3_BASE_URL_V2}home_page/home_hero_image_assets/hero_3_1280%402x.png`,
  hero_3_768_to_1150: `${MILES_WEB_S3_BASE_URL_V2}home_page/home_hero_image_assets/hero_3_1000%402x.png`,
  hero_3_600_ipad_mini: `${MILES_WEB_S3_BASE_URL_V2}home_page/home_hero_image_assets/hero_3_600%402x.png`,
  hero_3_mobile: `${MILES_WEB_S3_BASE_URL_V2}home_page/home_hero_image_assets/hero_3_mobile%402x.png`,
  home_page_hero_3_v2: `${MILES_WEB_S3_BASE_URL_V2}home_page/home_hero_image_assets/home_page_hero_3_v2%402x.png`,
  // section: hero_03 - end
  // section: we_are_highlighted - start
  we_are_highlighted_techCrunch_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/we_are_highlighted_techCrunch%402x.png`,
  we_are_highlighted_Forbes_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/we_are_highlighted_Forbes%402x.png`,
  we_are_highlighted_fastCompany_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/we_are_highlighted_fastCompany%402x.png`,
  we_are_highlighted_badcredit_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/we_are_highlighted_badcreadit%402x.png`,
  we_are_highlighted_card_rates_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/we_are_highlighted_card_rates%402x.png`,
  we_are_highlighted_curbed_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/we_are_highlighted_curbed%402x.png`,
  we_are_highlighted_geekwire_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/we_are_highlighted_geekwire%402x.png`,
  we_are_highlighted_greenBiz_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/we_are_highlighted_greenBiz%402x.png`,
  we_are_highlighted_mashable_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/we_are_highlighted_mashable%402x.png`,
  we_are_highlighted_nast_traveler_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/we_are_highlighted_nast_traveler%402x.png`,
  we_are_highlighted_ozy_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/we_are_highlighted_ozy%402x.png`,
  we_are_highlighted_skift_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/we_are_highlighted_skift%402x.png`,
  we_are_highlighted_the_points_guy_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/we_are_highlighted_the_points_guy%402x.png`,
  we_are_highlighted_the_verge_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/we_are_highlighted_the_verge%402x.png`,
  we_are_highlighted_wired_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/we_are_highlighted_wired%402x.png`,
  logo_2022_wired_v1: `https://getmiles.s3.amazonaws.com/assets/web_v2/home_page/logo_2022_01_wired_v1/Mask+Group%402x.png`,
  logo_2022_greekwire_v1: `https://getmiles.s3.amazonaws.com/assets/web_v2/home_page/logo_2022_02_greekwire_v2/Mask+Group%402x.png`,
  logo_2022_smartCitiesDrive_v1: `https://getmiles.s3.amazonaws.com/assets/web_v2/home_page/logo_2022_03_smartCitiesDrive_v1/Mask+Group%402x.png`,
  logo_2022_metro_v1: `https://getmiles.s3.amazonaws.com/assets/web_v2/home_page/logo_2022_04_metro_v4/Mask+Group%402x.png`,
  logo_2022_massTransit_v1: `https://getmiles.s3.amazonaws.com/assets/web_v2/home_page/logo_2022_05_massTransit_v1/Mask+Group%402x.png`,
  logo_2022_techCrunch_v1: `https://getmiles.s3.amazonaws.com/assets/web_v2/home_page/logo_2022_06_techCrumch_v1/Mask+Group%402x.png`,
  logo_2022_intelligentTransport_v1: `https://getmiles.s3.amazonaws.com/assets/web_v2/home_page/logo_2022_07_intelligentTransport_v1/Mask+Group%402x.png`,
  logo_2022_KQED_v1: `https://getmiles.s3.amazonaws.com/assets/web_v2/home_page/logo_2022_08_KQED_v1/Mask+Group%402x.png`,
  logo_2022_eastCountyToday_v1: `https://getmiles.s3.amazonaws.com/assets/web_v2/home_page/logo_2022_09_eastCountyToday_v1/Mask+Group%402x.png`,
  // section: we_are_highlighted - end
  // section: our_users_love - start
  our_users_love_avatar_01_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/our_users_love_avatar_01/group-87-copy-3%402x.png`,
  our_users_love_avatar_02_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/our_users_love_avatar_02/group-87-copy-9%402x.png`,
  our_users_love_avatar_03_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/our_users_love_avatar_03/group-87-copy-5%402x.png`,
  our_users_love_avatar_04_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/our_users_love_avatar_04/group-87-copy-7%402x.png`,
  our_users_love_avatar_05_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/our_users_love_avatar_05/group-87-copy-6%402x.png`,
  our_users_love_avatar_06_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/our_users_love_avatar_06/group-87-copy-4%402x.png`,
  our_users_love_avatar_07_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/our_users_love_avatar_07/group-87-copy-10%402x.png`,
  our_users_love_avatar_08_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/our_users_love_avatar_08_v2/group-3%402x.png`,
  our_users_love_avatar_09_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/our_users_love_avatar_09/group-87-copy-11%402x.png`,
  our_users_love_avatar_10_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/our_users_love_avatar_10/group-87-copy-8%402x.png`,
  our_users_love_avatar_11_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/our_users_love_avatar_11_v2/group-5%402x.png`,
  our_users_love_avatar_12_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/our_users_love_avatar_12_v2/group-4%402x.png`,
  logo_android_dark_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/logo_android_dark%402x.png`,
  logo_apple_dark_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/logo_apple_dark%402x.png`,
  // section: our_users_love - end
  carousel_arrow_left_img: `${MILES_WEB_S3_BASE_URL_V2}common_icon/carousel_arrow_v2/default_state/left_arrow/group-48-copy-4%402x.png`,
  carousel_arrow_right_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/carousel_arrow_right%402x.png`,
  apple_store_icon_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/apple_store_icon%402x.png`,
  google_play_icon_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/google_play_icon%402x.png`,
  star_icon_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/star_icon%402x.png`,
  icon_star_half_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/icon_star_half%402x.png`,
  star_icon_green_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/star_icon_green%402x.png`,
};

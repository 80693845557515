import { MILES_WEB_S3_BASE_URL_V2 } from './base';

export const jp_home_page_assets = {
  pre_footer_img: `${MILES_WEB_S3_BASE_URL_V2}footer/v2_pre_footer_largest%402x.png`,
  logo_fb_img: require('src/assets/Miles/footer/logo_fb@2x.png'),
  logo_ins_img: require('src/assets/Miles/footer/logo_ins@2x.png'),
  logo_linkedin_img: require('src/assets/Miles/footer/logo_linkedin@2x.png'),
  logo_twitter_img: require('src/assets/Miles/footer/logo_twitter@2x.png'),
  miles_logo_white_img: require('src/assets/Miles/footer/miles_logo_white@2x.png'),
  // section: hero_01 - start
  hero_1_1440: `${MILES_WEB_S3_BASE_URL_V2}jp_web/home_page/hero_1_1440_v2/group-2%402x.png`,
  hero_1_1280: `${MILES_WEB_S3_BASE_URL_V2}jp_web/home_page/hero_1_1280_v2/group-108%402x.png`,
  hero_1_768_to_1150: `${MILES_WEB_S3_BASE_URL_V2}jp_web/home_page/hero_1_768_to_1150_v2/group%402x.png`,
  hero_1_600_ipad_mini: `${MILES_WEB_S3_BASE_URL_V2}jp_web/home_page/hero_1_600_ipad_mini_v2/group-19%402x.png`,
  hero_1_360: `${MILES_WEB_S3_BASE_URL_V2}jp_web/home_page/hero_1_360_v2/group-19%402x.png`,
  // section: hero_01 - end
  // section: hero_02 - start
  icon_green_train_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/hero_02/icon_green_train%402x.png`,
  icon_blue_car_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/hero_02/icon_blue_car%402x.png`,
  icon_yellow_car_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/hero_02/icon_yellow_car%402x.png`,
  icon_blue_airplan_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/hero_02/icon_blue_airplan%402x.png`,
  icon_green_ship_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/hero_02/icon_green_ship%402x.png`,
  icon_walk_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/hero_02/icon_walk%402x.png`,
  icon_run_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/hero_02/icon_run%402x.png`,
  icon_ski_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/hero_02/icon_ski%402x.png`,
  hero_2_1440: `${MILES_WEB_S3_BASE_URL_V2}jp_web/home_page/hero_02_1440_v2/hero_2_1440%402x.png`,
  hero_2_1280: `${MILES_WEB_S3_BASE_URL_V2}jp_web/home_page/hero_02_1280_v2/hero_2_1280%402x.png`,
  hero_2_768_to_1150: `${MILES_WEB_S3_BASE_URL_V2}jp_web/home_page/hero_02_1000_v2/hero_2_768_to_1150%402x.png`,
  hero_2_600_ipad_mini: `${MILES_WEB_S3_BASE_URL_V2}jp_web/home_page/hero_02_600_v2/hero_2_600_ipad_mini%402x.png`,
  hero_2_360: `${MILES_WEB_S3_BASE_URL_V2}jp_web/home_page/hero_02_600_v2/hero_2_360%402x.png`,
  // section: hero_02 - end
  // section: hero_03 - start
  redeem_miles_icon_img: `${MILES_WEB_S3_BASE_URL_V2}jp_web/home_page/redeem_miles_icon_img_jp/group-41%402x.png`,
  hero_3_1440: `${MILES_WEB_S3_BASE_URL_V2}jp_web/home_page/hero_03_1440/group-3%402x.png`,
  hero_3_1280: `${MILES_WEB_S3_BASE_URL_V2}jp_web/home_page/hero_03_1280/group-3%402x.png`,
  hero_3_768_to_1150: `${MILES_WEB_S3_BASE_URL_V2}jp_web/home_page/hero_03_1000/group-3%402x.png`,
  hero_3_600_ipad_mini: `${MILES_WEB_S3_BASE_URL_V2}jp_web/home_page/hero_03_600/group-28%402x.png`,
  hero_3_360: `${MILES_WEB_S3_BASE_URL_V2}jp_web/home_page/hero_03_360/group-59%402x.png`,
  // section: hero_03 - end
  jp_home_page_esc_icon_img: `${MILES_WEB_S3_BASE_URL_V2}jp_web/home_page/jp_home_page_esc_icon_img/group-17%402x.png`,
  logo_android_dark_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/logo_android_dark%402x.png`,
  logo_apple_dark_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/logo_apple_dark%402x.png`,
  carousel_arrow_left_img: `${MILES_WEB_S3_BASE_URL_V2}common_icon/carousel_arrow_v2/default_state/left_arrow/group-48-copy-4%402x.png`,
  carousel_arrow_right_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/carousel_arrow_right%402x.png`,
  apple_store_icon_img: `${MILES_WEB_S3_BASE_URL_V2}jp_web/home_page/apple_store_icon_gray/apple-icon%402x.png`,
  google_play_icon_img: `${MILES_WEB_S3_BASE_URL_V2}jp_web/home_page/google_play_store_icon_gray/android-icon%402x.png`,
  star_icon_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/star_icon%402x.png`,
  icon_star_half_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/icon_star_half%402x.png`,
  star_icon_green_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/star_icon_green%402x.png`,
  brands_you_love_v3_2500_img: `${MILES_WEB_S3_BASE_URL_V2}jp_web/home_page/asset_partner_we_work_with_1440_v2/Group+683%403x.png`,
  brands_you_love_v3_600_img: `${MILES_WEB_S3_BASE_URL_V2}jp_web/home_page/asset_partner_we_work_with_600_v2/Group+114%403x.png`,
  brands_you_love_v3_360_img: `${MILES_WEB_S3_BASE_URL_V2}jp_web/home_page/asset_partner_we_work_with_360_v2/Group+83%403x.png`,
  we_care_your_privacy_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/we_care_your_privacy%402x.png`,
  // carousel
  carousel_raffle_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/carousel_raffle%402x.png`,
  carousel_giftcard_img: `${MILES_WEB_S3_BASE_URL_V2}jp_web/home_page/jp_carousel_giftcard%402x.png`,
  carousel_donation_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/carousel_donation%402x.png`,
  carousel_challenges_img: `${MILES_WEB_S3_BASE_URL_V2}jp_web/home_page/jp_carousel_challenges%402x.png`,
  active_raffle_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/active_raffle%402x.png`,
  active_giftcard_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/active_giftcard%402x.png`,
  active_donation_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/active_donation%402x.png`,
  active_challenges_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/active_challenges%402x.png`,
  fade_raffle_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/fade_raffle%402x.png`,
  fade_Challenges_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/fade_Challenges%402x.png`,
  fade_gift_card_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/fade_gift_card%402x.png`,
  fade_Donation_img: `${MILES_WEB_S3_BASE_URL_V2}home_page/fade_Donation%402x.png`,
};

import { MILES_WEB_S3_BASE_URL_V2 } from './base';

export const about_page_assets = {
  where_we_headed_2500_v2_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/where_we_headed_1440_v3/Group+8%402x.png`,
  where_we_headed_1280_v2_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/where_we_headed_1280_v3/Group+8%402x.png`,
  where_we_headed_1000_v2_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/where_we_headed_1000_v3/Group+8%402x.png`,
  where_we_headed_600_v2_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/where_we_headed_600_v3/Group+8%402x.png`,
  where_we_headed_360_v2_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/where_we_headed_360_v3/Group+88%402x.png`,
  where_we_headed_v3_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/where_we_headed/where_we_headed_v3%402x.png`,
  // carousel_01 start
  carousel_01_2016_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_carousel_01_v3/2016/group%402x.png`,
  carousel_01_2018_07_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_carousel_01_v3/2018_07/group%402x.png`,
  carousel_01_2019_03_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_carousel_01_v3/2019_03/group%402x.png`,
  carousel_01_2020_03_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_carousel_01_v3/2020_03/group-4%402x.png`,
  carousel_01_2020_03_v2_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_carousel_01_v3/2020_03/group-7%402x.png`,
  carousel_01_2020_05_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_carousel_01_v3/2020_05/group-6%402x.png`,
  carousel_01_2020_06_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_carousel_01_v3/2020_06/group%402x.png`,
  carousel_01_2020_06_v2_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_carousel_01_v3/2020_06/group-2%402x.png`,
  carousel_01_2020_08_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_carousel_01_v3/2020_08/group-14%402x.png`,
  carousel_01_2021_03_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_carousel_01_v3/2021_03/group%402x.png`,
  carousel_01_2021_04_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_carousel_01_v3/2021_04/group%402x.png`,
  carousel_01_2021_04_v2_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_carousel_01_v3/2021_04/group-3%402x.png`,
  carousel_01_2021_10_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/carousel_01/2021_10/group-126%402x.png`,
  // template_02
  carousel_01_2019_06_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_carousel_01_v3/template_02/2019_06/group-4%402x.png`,
  carousel_01_2019_06_v2_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_carousel_01_v3/template_02/2019_06/group-7%402x.png`,
  carousel_01_2020_09_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_carousel_01_v3/template_02/2020_09/group%402x.png`,
  carousel_01_2020_12_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_carousel_01_v3/template_02/2020_12/group-11%402x.png`,
  // template_03
  carousel_01_2021_05_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_carousel_01_v3/template_03/2021_05/group-9%402x.png`,
  carousel_01_2021_05_v2_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_carousel_01_v3/template_03/2021_05/group-10%402x.png`,
  carousel_01_2021_06_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_carousel_01_v3/template_03/2021_06/group-3%402x.png`,
  carousel_01_2021_07_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_carousel_01_v3/template_03/2021_07/group-5%402x.png`,
  carousel_01_2021_10_v2_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_carousel_01_v3/2021_10/group-1%402x.png`,
  // carousel_01 end
  // carousel_02 start
  carousel_02_Product_Design_Pradeep_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Product_Design/Pradeep+Periasamy/oval%402x.png`,
  carousel_02_Product_Design_Anita_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Product_Design/Anita+Liao/oval%402x.png`,
  carousel_02_Product_Design_Irene_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Product_Design/Irene+Kadir/oval%402x.png`,
  carousel_02_Product_Design_Sayyam_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Product_Design/Sayyam+Mehta/oval%402x.png`,
  carousel_02_Product_Design_Evan_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Product_Design/Evan+Shores/oval%402x.png`,
  carousel_02_Product_Design_Yuqi_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Product_Design/Yuqi+Lu/oval%402x.png`,
  carousel_02_Product_Design_Xinya_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Product_Design/Xinya+Wang/oval%402x.png`,
  carousel_02_Product_Design_Ying_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Product_Design/Ying+Xu/oval%402x.png`,
  carousel_02_Product_Design_Jing_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Product_Design/Jing+Li/oval%402x.png`,

  carousel_02_Product_Engineering_Amit_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Engineering/Amit+Soni/oval%402x.png`,
  carousel_02_Product_Engineering_Austin_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Engineering/Austin+Kelley/oval%402x.png`,
  carousel_02_Product_Engineering_Darshil_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Engineering/Darshil+Desai/oval%402x.png`,
  carousel_02_Product_Engineering_Doron_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Engineering/Doron+Lemze/oval%402x.png`,
  carousel_02_Product_Engineering_Gautam_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Engineering/Gautam+Adavi/oval%402x.png`,
  carousel_02_Product_Engineering_Janit_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Engineering/Janit+Modi/oval%402x.png`,
  carousel_02_Product_Engineering_Kashish_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Engineering/Kashish+Parikh/oval%402x.png`,
  carousel_02_Product_Engineering_Abhinav_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Engineering/Abhinav+Sharma/oval%402x.png`,
  carousel_02_Product_Engineering_Siddhi_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Engineering/Siddhi+Borkar/oval%402x.png`,
  carousel_02_Product_Engineering_Dylan_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Engineering/Dylan+Teare/oval%402x.png`,
  carousel_02_Product_Engineering_Eshan_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Engineering/Eshan+Toshniwal/oval%402x.png`,
  carousel_02_Product_Engineering_Harrison_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Engineering/Harrison+Oglesby/oval%402x.png`,
  carousel_02_Product_Engineering_John_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Engineering/John+Baer/oval%402x.png`,
  carousel_02_Product_Engineering_David_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Engineering/David+Matevosyan/oval%402x.png`,
  carousel_02_Product_Engineering_Vaibhav_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Engineering/Vaibhav+Shanbhag/oval%402x.png`,
  carousel_02_Product_Engineering_Mayukh_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Engineering/Mayukh+Samanta/oval%402x.png`,
  carousel_02_Product_Engineering_Navdeep_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Engineering/Navdeep+Patel/oval%402x.png`,
  carousel_02_Product_Engineering_Praharshit_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Engineering/Praharshit+Gorripaty++/oval%402x.png`,
  carousel_02_Product_Engineering_Prutha_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Engineering/Prutha+Kulkarni/oval%402x.png`,

  carousel_02_Product_Growth_Sales_Parker_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Growth_Sales/Parker+Davis/oval%402x.png`,
  carousel_02_Product_Growth_Sales_Ray_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Growth_Sales/Ray.+Dequina/oval%402x.png`,
  carousel_02_Product_Growth_Sales_Joann_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Growth_Sales/Joann+Panyarachun/oval%402x.png`,
  carousel_02_Product_Growth_Sales_Tiffany_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Growth_Sales/Tiffany+Liu/oval%402x.png`,
  carousel_02_Product_Growth_Sales_Lorena_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Growth_Sales/Lorena+Zapata/oval%402x.png`,

  carousel_02_Product_Support_Andy_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Support/Andy+Senter/oval%402x.png`,
  carousel_02_Product_Support_Christopher_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Support/Christopher.+P/oval%402x.png`,
  carousel_02_Product_Support_Estephania_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Support/Estephania.+M/oval%402x.png`,
  carousel_02_Product_Support_Nishita_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Support/Nishita+Shankar/oval%402x.png`,
  carousel_02_Product_Support_Jenny_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Support/Jenny+Trieu/oval%402x.png`,
  carousel_02_Product_Support_Stephany_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Support/Stephany+Aragon/oval%402x.png`,
  carousel_02_Product_Support_Ivan_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/meet_the_team/Support/Ivan+Maupome/oval%402x.png`,

  // carousel_02 end
  // carousel_03 end
  carousel_03_1440_01_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/carousel_03/1440/img_01_v2%402x.png`,
  carousel_03_1440_02_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/carousel_03/1440/img_02_v2%402x.png`,
  carousel_03_1440_03_img: `${MILES_WEB_S3_BASE_URL_V2}about_page/carousel_03/1440/img_03_v2%402x.png`,
  // carousel_03 end
  /* TODO Fix images for mobile once uploaded on zeplin */
  founders_picture_less_360: `${MILES_WEB_S3_BASE_URL_V2}about_page/founders_1000%402x.png`,
  founders_picture_600_ipad_mini: `${MILES_WEB_S3_BASE_URL_V2}about_page/founders_1000%402x.png`,
  founders_picture_768_to_1150: `${MILES_WEB_S3_BASE_URL_V2}about_page/founders_1000%402x.png`,
  founders_picture: `${MILES_WEB_S3_BASE_URL_V2}about_page/founders_1440%402x.png`,
  /* TODO Fix images for mobile once uploaded on zeplin */
  our_team_picture_less_360: `${MILES_WEB_S3_BASE_URL_V2}about_page/our_team_1000%402x.png`,
  our_team_picture_600_ipad_mini: `${MILES_WEB_S3_BASE_URL_V2}about_page/our_team_1000%402x.png`,
  our_team_picture_768_to_1150: `${MILES_WEB_S3_BASE_URL_V2}about_page/our_team_1000%402x.png`,
  our_team_picture: `${MILES_WEB_S3_BASE_URL_V2}about_page/our_team_1440%402x.png`,
  /* TODO Fix images for mobile once uploaded on zeplin */
  investors_picture_less_360: `${MILES_WEB_S3_BASE_URL_V2}about_page/investors_600%402x.png`,
  investors_picture_600_ipad_mini: `${MILES_WEB_S3_BASE_URL_V2}about_page/investors_600%402x.png`,
  investors_picture_768_to_1150: `${MILES_WEB_S3_BASE_URL_V2}about_page/investors_1000%402x.png`,
  investors_picture: `${MILES_WEB_S3_BASE_URL_V2}about_page/investors_1440%402x.png`,
  /* TODO Fix images for 1150 and lower sizes once uploaded on zeplin */
  work_at_miles_picture_less_360: `${MILES_WEB_S3_BASE_URL_V2}about_page/work_at_miles/work_at_miles_360_v2/group-143+1%403x.png`,
  work_at_miles_picture_600_ipad_mini: `${MILES_WEB_S3_BASE_URL_V2}about_page/work_at_miles/work_at_miles_600_v2/group-143+1%403x.png`,
  work_at_miles_picture_768_to_1150: `${MILES_WEB_S3_BASE_URL_V2}about_page/work_at_miles/work_at_miles_1000_v2/group-147+1%403x.png`,
  work_at_miles_picture: `${MILES_WEB_S3_BASE_URL_V2}about_page/work_at_miles/work_at_miles_1440_v2/group-148-copy+1%403x.png`,
  leadership_1: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_leadership/jigar_shah%402x.png`,
  leadership_2: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_leadership/paresh_jain%402x.png`,
  leadership_3: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_leadership/parin_shah%402x.png`,
  leadership_4: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_leadership/chirayu_krishnappa%402x.png`,
  leadership_5: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_leadership/ron_drabkin%402x.png`,
  leadership_8: `${MILES_WEB_S3_BASE_URL_V2}about_page/about_us_leadership/ryan_mendoza%402x.png`,
};

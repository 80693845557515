import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import milesUtils from "../../../utils/milesUtils";
import { MILES_ANIMATIONS } from "../../../utils/milesConstant";
import BoardOvalUnit from "../board_oval_unit/index";
import { Styled_body_wrap } from "./styles";

const {
  about_page_assets: assets,
} = config

let firstRender = true;

const {} = config;

const {
} = MILES_ANIMATIONS;


const MilesWeb_AboutPage: React.FC<{}> = observer(() => {

  if (firstRender) {
    (window as any).history.scrollRestoration = "manual";
    milesUtils.debug_log("component - MilesWeb_AboutPage -- firstRender");
  }

  useEffect(() => {
    milesUtils.debug_log("component - MilesWeb_AboutPage -- did mount");

    firstRender = false;
  }, []);

  useEffect(() => {}, []);

  return (
    <Styled_body_wrap>
      <div className="miles_ui_about_page_leadership1_root">
        <div className="miles_ui_about_page_leadership1_container">
          <BoardOvalUnit add_class="ciq_spacer1" oval_image={assets.leadership_1} leader_name="Jigar Shah" leader_designation="Co-Founder, CEO" />
          <BoardOvalUnit add_class="ciq_spacer1" oval_image={assets.leadership_2} leader_name="Paresh Jain" leader_designation="Co-Founder, CBO" />
          <BoardOvalUnit add_class="" oval_image={assets.leadership_8} leader_name="Ryan Mendoza" leader_designation="Partner at Scrum Ventures" />
        </div>
      </div>
    </Styled_body_wrap>
  );
});

export default MilesWeb_AboutPage;

import config from "../../utils/config";
import React from 'react';

const {
    help_page_assets: assets,
    currentSupportEmailAddress,
} = config;

export const RAW_DATA = {
    "response": {
        "user": [
            {
                "id": 1,
                "name": "アカウント設定について",
                "slug": "account-settings-preferences",
                "user": "user",
                "image_src": assets.help_page_category_icon_1,
                "question_array": [
                    {
                        "id": 68,
                        "text": "アプリの設定とおすすめ",
                        "slug": "setting-up-the-app-and-best-practices",
                        "answer_array": [
                            {
                                "id": 65,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    アカウント登録が完了したら、以下の設定をすることでMilesを快適にご利用いただけます。

                                    位置情報サービス：iOSデバイスの場合、位置情報サービスを「常に許可」に設定してください。「なし」または「このAppの使用中のみ許可」を選択すると、アプリが正常に機能しなくなります。位置情報サービスを設定するには、ホーム画面より「設定」→「プライバシー」→「位置情報サービス（オン）」→「Miles」を選択してください。iOSでは、「バックグランドでの位置情報の使用を許可したままにしますか?」 と表示されることがありますが、「常に許可する」を選択するとアプリが正常に動作します。
                                    Android端末では、位置情報サービスを「許可」に設定してください。位置情報サービスは、「設定」→「アプリケーション」→「Miles」→「アクセス許可」→「位置情報」から「オン」にすることで更新できます。

                                    通知の設定：製品の新機能、ボーナスマイル、新しい特典などに関する重要なアップデートを見逃さないために、「オン」にすることをおすすめします。
                                    iOSデバイスの場合、通知設定を更新するには、ホーム画面より「設定」→「通知」→「Miles」を選択してください。
                                    Android端末の場合、通知設定を更新するには、「設定」→「アプリケーション」→「Miles」→「通知」を選択してください。
                                    また、アプリ内で詳細な通知やEメールの設定を行うこともできます。アカウントページより「設定」を選択してください。

                                    主要な通勤・通学手段を設定する：マイルの獲得には必須ではありませんが、移動手段がより正確に判定されるように、主要な通勤・通学手段を設定することを強くおすすめします。主要な通勤・通学手段を設定は、アプリに初めてログインしたときにホームページより行え、その後はアプリのアカウントページで「主要な通勤・通学手段を設定する」をタップして行うことができます。

                                    ログイン状態を維持する：意図せずにアプリからログアウトしていないか確認してください。

                                    アプリを開く：アプリを定期的に開き、バッテリーの最適化やその他の理由によってアプリが意図せずに閉じていないか確認してください。

                                    アプリのバージョン：当社は、新機能の追加、バグの修正、体験やパフォーマンスの向上のために、アプリのアップデートを定期的に公開しています。アプリを最新の状態にしておくことをおすすめします。

                                    OSのバージョン：アプリが正常に機能するために、利用可能な場合は最新のOSバージョンにアップデートしてください。

                                    Wi-Fi：アプリが機能するために必須ではありませんが、Wi-Fiを有効にすることで、パフォーマンス精度をさらに向上させ、バッテリーの消費を抑えることができます。

                                    `}
                                </span></div>
                            }
                        ]
                    },
                    {
                        "id": 69,
                        "text": "プロフィールページに自分の写真を追加するにはどうすればいいですか？",
                        "slug": "how-can-i-add-my-picture-to-my-profile-page",
                        "answer_array": [
                            {
                                "id": 66,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    Miles アプリのホーム> 左上の人型のアイコン>もっと見る>アイコンの下にある編集マーク>アイコンの下にある＋マークでアバターを選択したり、Facebookのプロフィール写真を使用できます。

                                    なお、プロフィールページに設定されたFacebookのプロフィール写真もしくはアバターは他のユーザーからは閲覧できるものではないため、ご安心ください。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    // {
                    //     "id": 70,
                    //     "text": "Why don’t I see the ride hailing services I use in the Connectors?",
                    //     "slug": "why-don-t-i-see-the-ride-hailing-services-i-use-in-the-connectors",
                    //     "answer_array": [
                    //         {
                    //             "id": 67,
                    //             "text": <div className="answer"><span><p><span>We’ve currently built connectors with mobility service providers like Uber and Lyft. We plan to expand that list to other service providers or fitness apps, as long as such services/apps allow us to do it. We will notify our users when we introduce a connector to a new service/app. Please provide your suggestions by using the ‘Send Feedback’ button towards the bottom of the Account page of the app or by emailing us at {currentSupportEmailAddress}.</span></p></span></div>
                    //         }
                    //     ]
                    // },
                    {
                        "id": 71,
                        "text": "通知の設定はどうすればいいですか？",
                        "slug": "how-can-i-set-my-notification-preferences",
                        "answer_array": [
                            {
                                "id": 68,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    アプリの新機能、ボーナスマイル、新しい特典などに関する重要なアップデートを見逃さないために、通知の設定を「オン」にすることをおすすめします。
                                    
                                    プッシュ通知は、iOSの場合、iOSの端末設定> Miles アプリを選択>通知で設定変更が可能です。
                                    Androidの場合 Miles アプリを長押し>アプリ情報>通知で設定変更が可能です。
                                    
                                    メール通知は、受信されたお知らせメール下部の「メールの配信停止」から通知設定の更新が可能です。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 72,
                        "text": "位置情報サービスの設定はどうすればいいですか？",
                        "slug": "how-can-i-set-my-location-services-preferences",
                        "answer_array": [
                            {
                                "id": 69,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    iOSデバイスの場合、ホーム画面より「設定」→「プライバシー」→「位置情報サービス（オン）」→「Miles」を選択してください。
                                    Android端末では、位置情報サービスは、「設定」→「アプリケーション」→「Miles」→「アクセス許可」→「位置情報」から「オン」にすることで更新できます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 73,
                        "text": "アプリからログアウトするにはどうすればいいですか？",
                        "slug": "how-can-i-logout-of-the-app",
                        "answer_array": [
                            {
                                "id": 70,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    ログアウト方法は以下になります。

                                    Miles アプリのホーム> 左上の人型のアイコン>設定>ログアウト
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 74,
                        "text": "パスワードを再設定するにはどうすればいいですか？",
                        "slug": "how-to-reset-password",
                        "answer_array": [
                            {
                                "id": 71,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    メールアドレスを使って再ログインする場合は、「パスワードをお忘れですか？」をタップしてください。以前にサインアップしたときに使用したEメールアドレスを入力するよう求められ、このメールアドレスにパスワードをリセットするためのリンクを含む認証メールが送信されます。リンクをタップして、パスワードをリセットしてください。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 75,
                        "text": "メンテナンスマイルとは何ですか？",
                        "slug": "what-is-a-maintenance-fee",
                        "answer_array": [
                            {
                                "id": 72,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    より快適なユーザー体験を維持し続けるために、2ヶ月以上Miles アプリを開いていない場合には、メンテナンスマイルが差し引かれます。このメンテナンスマイルが適用されるとアカウントのマイル残高は0になります。なお、Miles アプリは無料でご利用可能です。無料会員様の場合、マイル残高からメンテナンスマイルによりマイルが差し引かれるだけで、金銭的な請求はありませんのでご安心ください。
                                    
                                    なお、メンテナンスマイルが発生することを知らなかった、もしくはその他やむを得ない事情により、差し引かれたマイルのお戻しをご希望の場合は、メンテナンスマイルが差し引かれた日から30日以内に、「アプリのホーム > 左上の人型のアイコン > お問い合わせ > 問題を報告する 」の手順でご連絡ください。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 76,
                        "text": "アカウント削除について",
                        "slug": "deleting-an-account",
                        "answer_array": [
                            {
                                "id": 73,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    アカウント削除依頼は、アプリ内から申請ができます。

                                    まず、アカウント削除画面へのアクセス方法をご案内いたします。
                                    
                                    アカウント削除希望のアカウントでMiles アプリにログイン>画面左上の人型のアイコン>設定>プライバシー>アカウント削除
                                    
                                    アカウント削除画面が表示されましたら、確認画面の内容をご確認いただき、アカウト削除希望の場合は『アカウントを削除しますか？「続ける」』をタップ>アカウント削除の理由を選択>ご登録メールアドレス宛に送られた認証コードを入力>確認画面の内容をご確認いただき「アカウント削除」をタップ>『アカウントを削除してよろしいですか？「OK」「キャンセル」』の「OK」をタップ>『削除依頼が完了しました。アカウントは7日後に削除されます「OK」』の完了画面が出ますので「OK」をタップしますとアカウント削除依頼は完了し、アプリからログアウトした状態になります。
                                    
                                    アカウント削除依頼から7日以内にアカウントは削除されます。

                                    なお、申請後はすぐにアカウントへのアクセスができなくなるため、ログインはできません。また、アカウント削除依頼後にアカウント復旧はできませんのでご了承ください。

                                    アカウント削除希望のアカウントでMiles アプリにログインしているかどうかの確認は、以下の手順でご確認ください。

                                    ログイン中のアプリのホーム > 左上の人型のアイコン > もっと見る をタップしますと、現在アプリにログイン中のメールアドレスをご確認いただけます。

                                    本FAQの手順でアカウント削除申請ができない場合は、Miles アプリのバージョンが古いことが考えられますのでMiles アプリをアップデートして再度お試しください。

                                    なお、上記手順でアプリからのアカウント削除申請後、アプリからのアカウント削除申請依頼が完了しているかどうかのご確認は、アカウント削除されたメールアドレスでアプリにログインを試みますと「Sign Up こちらのメールアドレスでログインはできません。別のメールアドレスをご利用ください」と表示されますので、そちらをご確認ください

                                    もしも上記手順でアプリ内からのアカウント削除申請手続きができない場合は、アカウント削除希望のアカウントでMiles アプリにログインいただき、以下の方法でご連絡いただきますようお願い申し上げます。

                                    アプリのホーム > 左上の人型のアイコン > お問い合わせ > 問題を報告する > アカウント設定 >「Eメールやアカウント情報の更新」を選択し、「アカウント削除希望/ アプリからアカウント削除できなかった」旨を記載の上、ご連絡ください。

                                    ※問い合わせ本文内に会員IDやご登録メールアドレスの記載は不要です。

                                    上記手順でお問い合わせいただくと、お客様がログイン中のアプリのご登録メールアドレスがカスタマーサポートで確認できるようになっております。

                                    なお、Miles アプリは無料です。アカウント削除せずにご登録されたままであっても、Milesからお客様のアカウントに課金が発生することは一切ありません。
                                    `}
                                    </span></div>
                            }
                        ]
                    }
                ]
            },
            {
                "id": 2,
                "name": "インストール・初期設定について",
                "slug": "app-installation-setup",
                "user": "user",
                "image_src": assets.help_page_category_icon_2,
                "question_array": [
                    {
                        "id": 3,
                        "text": "アプリのインストールについて",
                        "slug": "installing-the-app",
                        "answer_array": [
                            {
                                "id": 3,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    App StoreまたはGoogle Play Storeで、Milesアプリを検索してダウンロードしてください。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 4,
                        "text": "アカウントの作成について",
                        "slug": "creating-an-account",
                        "answer_array": [
                            {
                                "id": 4,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    以下のいずれかの方法で、Milesアプリよりアカウントを作成してください：

                                    Facebook：登録ページで「Facebookでログイン」をタップし、その後の手順に従ってください。Milesは、利用者のFacebookアカウントに投稿することはありません。
                                    Eメール：登録ページで「登録する」をタップし、有効なEメールアドレス、パスワード、名前を入力してください。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 5,
                        "text": "アプリの設定とおすすめ",
                        "slug": "setting-up-the-app-and-best-practices",
                        "answer_array": [
                            {
                                "id": 5,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    アカウント登録が完了したら、以下の設定をすることでMilesを快適にご利用いただけます。

                                    位置情報サービス：iOSデバイスの場合、位置情報サービスを「常に許可」に設定してください。「なし」または「このAppの使用中のみ許可」を選択すると、アプリが正常に機能しなくなります。位置情報サービスを設定するには、ホーム画面より「設定」→「プライバシー」→「位置情報サービス（オン）」→「Miles」を選択してください。iOSでは、「バックグランドでの位置情報の使用を許可したままにしますか?」 と表示されることがありますが、「常に許可する」を選択するとアプリが正常に動作します。
                                    Android端末では、位置情報サービスを「許可」に設定してください。位置情報サービスは、「設定」→「アプリケーション」→「Miles」→「アクセス許可」→「位置情報」から「オン」にすることで更新できます。
                                    
                                    通知の設定：製品の新機能、ボーナスマイル、新しい特典などに関する重要なアップデートを見逃さないために、「オン」にすることをおすすめします。
                                    iOSデバイスの場合、通知設定を更新するには、ホーム画面より「設定」→「通知」→「Miles」を選択してください。
                                    Android端末の場合、通知設定を更新するには、「設定」→「アプリケーション」→「Miles」→「通知」を選択してください。
                                    また、アプリ内で詳細な通知やEメールの設定を行うこともできます。アカウントページより「設定」を選択してください。
                                    
                                    主要な通勤・通学手段を設定する：マイルの獲得には必須ではありませんが、移動手段がより正確に判定されるように、主要な通勤・通学手段を設定することを強くおすすめします。主要な通勤・通学手段を設定は、アプリに初めてログインしたときにホームページより行え、その後はアプリのアカウントページで「主要な通勤・通学手段を設定する」をタップして行うことができます。
                                    
                                    ログイン状態を維持する：意図せずにアプリからログアウトしていないか確認してください。
                                    
                                    アプリを開く：アプリを定期的に開き、バッテリーの最適化やその他の理由によってアプリが意図せずに閉じていないか確認してください。
                                    
                                    アプリのバージョン：当社は、新機能の追加、バグの修正、体験やパフォーマンスの向上のために、アプリのアップデートを定期的に公開しています。アプリを最新の状態にしておくことをおすすめします。
                                    
                                    OSのバージョン：アプリが正常に機能するために、利用可能な場合は最新のOSバージョンにアップデートしてください。
                                    
                                    Wi-Fi：アプリが機能するために必須ではありませんが、Wi-Fiを有効にすることで、パフォーマンス精度をさらに向上させ、バッテリーの消費を抑えることができます。
                                    
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 6,
                        "text": "マイルをためるためにデータプランは必要ですか？",
                        "slug": "do-i-need-a-data-plan-to-earn-miles",
                        "answer_array": [
                            {
                                "id": 6,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    Milesは、インターネットに接続されていないオフライン手段でも動作します。オフライン手段で使用する場合、少なくとも24時間に1回インターネット（Wi-Fiまたはデータプラン）に接続することで、過去24時間の移動のマイルが獲得できます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 7,
                        "text": "なぜ主要な通勤・通学手段を設定する必要があるのですか？",
                        "slug": "why-should-i-set-a-primary-mode",
                        "answer_array": [
                            {
                                "id": 7,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    マイルをためるために必須ではありませんが、移動手段が正しく判別されるよう、主要な通勤・通学手段を設定することをおすすめします。例えば、渋滞の中、車で短距離移動した場合、誤って徒歩やランニングの移動と分類されることがあります。主要な通勤・通学手段を選択することで、最も頻繁に利用する移動手段を登録することができます。アプリのアカウントページで「主要な通勤・通学手段を設定する」をタップすると、主要な通勤・通学手段を設定することができます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 8,
                        "text": "プロフィールページに自分の写真を追加するにはどうすればいいですか？",
                        "slug": "how-can-i-add-my-picture-to-my-profile-page",
                        "answer_array": [
                            {
                                "id": 8,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    Miles アプリのホーム> 左上の人型のアイコン>もっと見る>アイコンの下にある編集マーク>アイコンの下にある＋マークでアバターを選択したり、Facebookのプロフィール写真を使用できます。

                                    なお、プロフィールページに設定されたFacebookのプロフィール写真もしくはアバターは他のユーザーからは閲覧できるものではないため、ご安心ください。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    // {
                    //     "id": 9,
                    //     "text": "Why don’t I see the ride hailing services I use in the Connectors?",
                    //     "slug": "why-don-t-i-see-the-ride-hailing-services-i-use-in-the-connectors",
                    //     "answer_array": [
                    //         {
                    //             "id": 9,
                    //             "text": <div className="answer"><span><p><span>We’ve currently built connectors with mobility service providers like Uber and Lyft. We plan to expand that list to other service providers or fitness apps, as long as such services/apps allow us to do it. We will notify our users when we introduce a connector to a new service/app. Please provide your suggestions by using the ‘Send Feedback’ button towards the bottom of the Account page of the app or by emailing us at {currentSupportEmailAddress}.</span></p></span></div>
                    //         }
                    //     ]
                    // },
                    {
                        "id": 10,
                        "text": "通知の設定はどうすればいいですか？",
                        "slug": "how-can-i-set-my-notification-preferences",
                        "answer_array": [
                            {
                                "id": 10,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    アプリの新機能、ボーナスマイル、新しい特典などに関する重要なアップデートを見逃さないために、通知の設定を「オン」にすることをおすすめします。

                                    プッシュ通知は、iOSの場合、iOSの端末設定> Miles アプリを選択>通知で設定変更が可能です。
                                    Androidの場合 Miles アプリを長押し>アプリ情報>通知で設定変更が可能です。

                                    メール通知は、受信されたお知らせメール下部の「メールの配信停止」から通知設定の更新が可能です。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 11,
                        "text": "位置情報サービスの設定はどうすればいいですか？",
                        "slug": "how-can-i-set-my-location-services-preferences",
                        "answer_array": [
                            {
                                "id": 11,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    "iOSデバイスの場合、ホーム画面より「設定」→「プライバシー」→「位置情報サービス（オン）」→「Miles」を選択してください。
                                    Android端末では、位置情報サービスは、「設定」→「アプリケーション」→「Miles」→「アクセス許可」→「位置情報」から「オン」にすることで更新できます。"
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 12,
                        "text": "アプリが動作しているかどうかを確認するにはどうすればいいですか？",
                        "slug": "how-do-i-know-if-the-app-is-working",
                        "answer_array": [
                            {
                                "id": 12,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    アプリが正常に機能しない場合、理由は様々ですが、以下の条件を満たしていれば、アプリが正常に機能する可能性が高くなります。

                                    位置情報サービス：
                                    iOSデバイスの場合、位置情報サービスを「常に許可」に設定してください。「なし」または「このAppの使用中のみ許可」を選択すると、アプリが正常に機能しなくなります。位置情報サービスを設定するには、ホーム画面より「設定」→「プライバシー」→「位置情報サービス（オン）」→「Miles」を選択してください。iOSでは、「バックグランドでの位置情報の使用を許可したままにしますか?」 と表示されることがありますが、「常に許可する」を選択するとアプリが正常に動作します。
                                    Android端末では、位置情報サービスを「許可」に設定してください。位置情報サービスは、「設定」→「アプリケーション」→「Miles」→「アクセス許可」→「位置情報」から「オン」にすることで更新できます。
                                    
                                    ログイン状態を維持する：意図せずにアプリからログアウトしていないか確認してください。
                                    
                                    アプリのバージョン：当社は、新機能の追加、バグの修正、体験やパフォーマンスの向上のために、アプリのアップデートを定期的に公開しています。アプリを最新の状態にしておくことをおすすめします。
                                    
                                    OSのバージョン：アプリが正常に機能するために、利用可能な場合は最新のOSバージョンにアップデートしてください。
                                    
                                    インターネット接続環境：Milesは、インターネットに接続されていないオフライン手段でも動作します。オフライン手段で使用する場合、少なくとも24時間に1回インターネット（Wi-Fiまたはデータプラン）に接続することで、過去24時間の移動のマイルが獲得できます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 13,
                        "text": "アプリからログアウトするにはどうすればいいですか？",
                        "slug": "how-can-i-logout-of-the-app",
                        "answer_array": [
                            {
                                "id": 13,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    ログアウト方法は以下になります。

                                    Miles アプリのホーム> 左上の人型のアイコン>設定>ログアウト
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 14,
                        "text": "パスワードを再設定するにはどうすればいいですか？",
                        "slug": "how-to-reset-password",
                        "answer_array": [
                            {
                                "id": 14,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    メールアドレスを使って再ログインする場合は、「パスワードをお忘れですか？」をタップしてください。以前にサインアップしたときに使用したEメールアドレスを入力するよう求められ、このメールアドレスにパスワードをリセットするためのリンクを含む認証メールが送信されます。リンクをタップして、パスワードをリセットしてください。
                                    `}
                                    </span></div>
                            }
                        ]
                    }
                ]
            },
            {
                "id": 3,
                "name": "データプライバシー",
                "slug": "data-privacy",
                "user": "user",
                "image_src": assets.help_page_category_icon_3,
                "question_array": [
                    {
                        "id": 15,
                        "text": "利用規約やプライバシーポリシーはアプリのどこに記載されていますか？",
                        "slug": "where-can-i-find-the-terms-of-services-and-the-privacy-policy-in-the-app",
                        "answer_array": [
                            {
                                "id": 52,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    利用規約とプライバシーポリシーは、アプリホーム画面>左上の人型のアイコン>「アプリについて」をタップすると表示されます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 16,
                        "text": "Milesアプリを使用することでどのような情報が収集されるのですか？",
                        "slug": "what-information-is-collected-through-my-use-of-the-miles-app",
                        "answer_array": [
                            {
                                "id": 53,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    利用者が当社に提供する情報

                                    当社は、利用者が当社に直接提供する情報を収集します。 例えば、当社は次のようなときに情報を収集します：利用者がアカウントを作成したとき、本サービスのインタラクティブ機能に参加したとき、フォームを記入したとき、プロフィール写真をアップロードしたとき、コンテストやプロモーションに参加したとき、特典を交換したとき、カスタマーサポートに連絡したとき、またはその他の方法で当社と連絡を取ったとき、等。当社が収集する可能性のある情報には、利用者の氏名、電話番号、Eメールアドレス、プロフィール写真、および利用者ご自身が提供されるその他の情報が含まれます。
                                    
                                    本サービスの利用に関する情報
                                    
                                    利用情報
                                    
                                    当社は、利用者の本サービスの利用に関する情報を収集します。当社は、利用者の位置を特定するために、IPアドレス、GPS、およびその他のセンサーを含む、様々な技術を使用しており、それによって例えば近くにあるデバイス、Wi-Fiアクセスポイント、基地局に関する情報を収集することがあります。また、コンパス、加速度センサー、ジャイロセンサーなど、利用者のデバイスに搭載されている他のセンサーから情報を収集することもあります。
                                    
                                    自動的に収集される情報
                                    
                                    利用者が本サービスにアクセスしたり、本サービスを利用したりすると、当社は以下のような情報を自動的に収集します。
                                    
                                    ログ情報： 当社は、利用者が使用しているOSのバージョン、アプリのバージョン、デバイスID、およびその他のアプリ内またはオンラインアクティビティ（利用者パス、アクセス時間、閲覧ページなど）を含む、本サービスの利用に関するログ情報を収集します。
                                    デバイス情報： 当社は、利用者が当社のサービスにアクセスするために使用するコンピュータまたはモバイル機器に関する情報を収集します。これには、ハードウェアモデル、オペレーティングシステムおよびバージョン、機器固有の識別子、およびモバイルネットワーク情報が含まれます。
                                    位置情報：上述のとおり、利用者のデバイスの許可設定に従って、当社は、デバイスから取得された移動データ（加速度など）を含む、デバイスの正確な位置および移動に関する情報を収集します。詳細については、以下の「利用者の選択」をご覧ください。
                                    クッキーおよびその他のトラッキング技術によって収集される情報：多くのオンラインサービスやモバイルアプリケーションと同様に、当社は、クッキーやウェブビーコン、ウェブストレージ、広告用のユニークな識別子などの技術を使用して、利用者の活動、ブラウザ、およびデバイスに関する情報を収集することがあります。クッキーとは、利用者のハードドライブまたはデバイスのメモリに保存される小さなデータファイルのことで、当社のサービスおよび利用者の体験を向上させ、当社のサービスのどの機能に人気があるか等を確認し、訪問回数をカウントするのに役立ちます。 ウェブビーコンとは、当社のサービスまたはEメールで使用される電子画像であり、クッキーの配信、訪問回数のカウント、利用状況やキャンペーンの効果の把握に役立ちます。クッキーに関する詳細およびクッキーを無効にする方法については、以下の「利用者の選択」をご覧ください。
                                    
                                    当社が他の情報源から収集する情報
                                    
                                    当社は、他の情報源から情報を取得し、それを当社のサービスを通じて収集した情報と組み合わせることがあります。例えば、利用者がソーシャル・メディア・サイトを通じてアカウントを作成またはログインした場合、当社は、当該ソーシャル・メディア・サイトが定める承認手続きに従って、利用者の氏名、性別、写真、アカウント情報、友人リストなど、当該サイトの特定の情報にアクセスすることができます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 17,
                        "text": "収集された情報はどのように利用されるのですか？",
                        "slug": "how-is-information-collected-used",
                        "answer_array": [
                            {
                                "id": 54,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    当社は、本サービスの主要機能（移動、ジオロケーションサービスなど）のパフォーマンスを向上するため、またはりアカウントを管理するためなど、当社のサービスを提供、維持、改善するために、収集した情報を使用します。 また、当社は収集した情報を以下の目的で使用します。

                                    技術的な通知、アップデート、セキュリティアラート、サポートおよび管理用メッセージを利用者に送信し、利用者のコメント、質問、カスタマーサービスのリクエストに対応する。
                                    Milesまたは第三者が提供する製品、サービス、オファー、プロモーション、イベントについて利用者に連絡すること、または、利用者が関心を持つと思われる本サービスまたは第三者に関連するニュースや情報を提供すること。
                                    当社のサービスに関連するトレンド、利用状況、活動をモニタリング、分析する。
                                    詐欺的な取引やその他の違法行為を検出、調査、防止し、Miles社および利用者やパートナーの権利や資産を保護する。
                                    本サービスをパーソナライズして改善し、利用者のプロファイルや興味に合った広告、コンテンツ、機能を提供する。
                                    Milesおよび第三者のコンテスト、懸賞、プロモーションを促進し、エントリーや特典を処理して提供する。
                                    当社の法的義務を果たすため。
                                    利用規約の施行、または利用規約に関連した使用。
                                    情報が収集された時点で利用者に説明されたその他の目的の遂行。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 18,
                        "text": "収集された情報はどのように共有されるのですか？",
                        "slug": "how-is-information-collected-shared",
                        "answer_array": [
                            {
                                "id": 55,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    当社は、以下のとおり、または本プライバシーポリシーに記載されているとおり、利用者に関する情報を共有することがあります：

                                    当社に代わって業務を遂行するために当該情報へのアクセスを必要とするベンダー、コンサルタント、およびその他のサービスプロバイダーと。
                                    利用者が本サービスからのログアウトまたはモバイルアプリケーションの削除によって情報の共有を拒否しない限り、第三者のサービスおよび一般的なマーケティング目的のために、匿名化された、集約された、または非識別化された形で、第三者と。
                                    情報の要求に応じて、適用される法律または法的手続き（国家安全保障または法執行の要件を満たすための公的機関による合法的な要求を含む）に従って、またはそれらによって開示が必要であると当社が判断した場合。
                                    利用者の行為が当社の利用者同意書やポリシーに反していると当社が判断した場合、またはMilesもしくは他者の権利、資産、安全を守るため。
                                    合併、会社資産の売却、資金調達、または他社による当社事業の全部または一部の買収に関連して、またはその交渉中に。
                                    Milesと当社の現在および将来の親会社、関連会社、子会社、および共通の支配と所有下にあるその他の企業との間での使用。
                                    利用者の同意がある場合、または利用者の指示がある場合。
                                    当社は、利用者を特定するために合理的に使用することができない、集約された情報または非識別情報を共有することがあります。
                                    `}
                                    </span></div>
                            }
                        ]
                    }
                ]
            },
            {
                "id": 4,
                "name": "その他",
                "slug": "general",
                "user": "user",
                "image_src": assets.help_page_category_icon_4,
                "question_array": [
                    {
                        "id": 19,
                        "text": "Milesのアプリはどのくらいバッテリーを消費しますか？",
                        "slug": "how-much-battery-does-miles-app-consume",
                        "answer_array": [
                            {
                                "id": 58,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    Milesは、静止しているときはバッテリーを消費しません。移動するときのバッテリー消費量は最小限に抑えられ、平均的な日々の移動では、バッテリーの1％程度が消費されます。Wi-Fiを常にオンにしておくことで、バッテリー消費を抑えることができます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 20,
                        "text": "複数のデバイスで利用できますか？",
                        "slug": "can-i-use-the-app-on-multiple-devices",
                        "answer_array": [
                            {
                                "id": 59,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    利用できますが、ログインしている1つのデバイスでしかマイルはたまりません。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 21,
                        "text": "アプリのデータ使用量は？",
                        "slug": "how-much-data-does-the-app-use",
                        "answer_array": [
                            {
                                "id": 60,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    データ使用量は、移動回数や各移動の距離によって異なりますが、平均的な月間データプランの1～2％程度を使用することがあります。データ使用量を軽減するためには、Wi-Fiの使用をおすすめします。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    // {
                    //     "id": 22,
                    //     "text": "アプリのホームページにある「お気に入り」とは何ですか？",
                    //     "slug": "what-is-my-places-on-the-home-page-of-the-app",
                    //     "answer_array": [
                    //         {
                    //             "id": 61,
                    //             "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                    //                 {`
                    //                 お気に入りの場所やよく行く場所をアプリ内に「お気に入り」として保存することができます。一度保存すると、アプリ内の機能を使ってその場所に移動したり、ETAを共有したり、連絡先と場所を共有したりすることができます。
                    //                 `}
                    //                 </span></div>
                    //         }
                    //     ]
                    // },
                    // {
                    //     "id": 23,
                    //     "text": "「お気に入り」に場所を追加する方法は？",
                    //     "slug": "how-can-you-add-locations-to-my-places",
                    //     "answer_array": [
                    //         {
                    //             "id": 62,
                    //             "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                    //                 {`
                    //                 新しい場所を追加するには、「お気に入り」セクションのプラス記号をタップして、住所を検索してください。
                    //                 `}
                    //                 </span></div>
                    //         }
                    //     ]
                    // },
                    {
                        "id": 24,
                        "text": "Miles での不正利用が確認された場合",
                        "slug": "why-was-my-account-removed",
                        "answer_array": [
                            {
                                "id": 63,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                {`
                                Miles では、弊社の利用規約に記載されたガイドラインに従って、Miles アプリご利用いただくユーザーに対してマイルを付与しています。

                                複数のアカウントを利用する、不正な目的を持って利用する、何らかの方法でシステムを操作するなど、当該ユーザーがプラットフォームを悪用しているとMiles が判断した場合、Miles は当該ユーザー及び当該ユーザーと関連するアカウントを確認できた場合はそれらのアカウントを削除する権利を有します。
                                `}
                                </span></div>
                            }
                        ]
                    },
                    {
                        "id": 25,
                        "text": "移動をブーストするにはどうしたらいいですか？",
                        "slug": "how-do-i-boost-a-trip",
                        "answer_array": [
                            {
                                "id": 64,
                                "text": <div className="answer"><span><p><span>移動をブーストするには、まず移動履歴ページで、ブーストしたい移動を探してください。見つかったら、その移動の左下にあるブーストボタンをタップしてください。マイルが2倍になります。
                                </span></p></span></div>
                            }
                        ]
                    },
                    {
                        "id": 26,
                        "text": "無料会員の場合、どのように移動マイルを獲得できますか？",
                        "slug": "how-do-i-claim-trip-with-free-subscription",
                        "answer_array": [
                            {
                                "id": 65,
                                "text": <div className="answer"><span><p><span>移動履歴ページで移動を見つけていただき、その右上にある緑色の「XXXマイルを獲得する」ボタンをタップしてください。完了すると、マイルがアカウントに付与されます。</span></p></span></div>
                            }
                        ]
                    },
                    {
                        "id": 27,
                        "text": "移動マイル獲得手続きをしなくても、マイルはたまりますか？",
                        "slug": "will-i-earn-miles-if-i-dont-claim-a-trip",
                        "answer_array": [
                            {
                                "id": 66,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                {`
                                無料会員の方の場合、手動で移動マイル獲得手続きをされなかった場合、移動のマイルは獲得できません。
                                Miles+会員の方は、移動マイル獲得は自動的に行われますので、アプリから手動で移動マイル獲得手続きをしていただく必要はございません。
                                `}
                                </span></div>
                            }
                        ]
                    },
                    {
                        "id": 28,
                        "text": "移動でどのくらいマイルを獲得できますか？",
                        "slug": "how-many-miles-will-i-earn-with-my-trips",
                        "answer_array": [
                            {
                                "id": 67,
                                "text": <div className="answer"><span><p><span>1マイル （約1.6キロメートル）の移動に対して1マイルのポイントをためる事ができます。環境やさしい移動や、健康的な移動手段での移動の場合、より多くのボーナスマイル（ポイント）が付与されます。バス、電車、船での移動の場合は1マイルの移動につき2倍のマイルが、自転車、ランニング、徒歩での移動の場合は、1マイルの移動につき3倍のマイルが付与されます。</span></p></span></div>
                            }
                        ]
                    },
                    {
                        "id": 29,
                        "text": "各ステータスの特典は何ですか？",
                        "slug": "what-are-the-benefits-of-each-status",
                        "answer_array": [
                            {
                                "id": 68,
                                "text": <div className="answer"><span><p><span>Miles アプリ内「ホームタブ」より、右上にあるご自身の会員ステータス（シルバー／ゴールド／プラチナ／ダイヤ）をタップし、次のページの右上にある各ステータスの色が並んだマークをタップしてご確認ください。</span></p></span></div>
                            }
                        ]
                    },
                    {
                        "id": 30,
                        "text": "ギフトカードの交換に制限があるのはなぜですか？",
                        "slug": "what-are-the-restrictions-for-giftcard-redemption",
                        "answer_array": [
                            {
                                "id": 69,
                                "text": <div className="answer"><span><p><span>ギフトカード特典は人気が高く、ご利用される方が多いため、より多くの方にギフトカード特典の交換機会を提供するために、月間の交換制限を設けています。</span></p></span></div>
                            }
                        ]
                    },
                    {
                        "id": 31,
                        "text": "有料会員と無料会員の違いは何ですか？",
                        "slug": "what-is-the-difference-between-paid-membership-and-free-membership",
                        "answer_array": [
                            {
                                "id": 70,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    無料会員では、移動マイルを手動で獲得する必要があり、1日最大5回の移動マイルを獲得できます。各移動マイル獲得では、1回の移動につき、最大10マイルまでは等倍、それ以降は移動距離に対し10%のマイル加算となります。（例：100マイルの移動距離の場合、最初の10マイル+残り90マイルの10% = 獲得移動マイル19マイル）。また、2倍の移動マイルを獲得できるブーストが1回/日、7日間の移動履歴閲覧が可能です。無料会員は、62,500マイル/月までギフトカードに交換可能です。無料会員として最初の3ヶ月以内に7,500マイルを獲得すると、15,000ボーナスマイルを獲得するチャンスがあります。（2023年11月1日以降の新規入会者が対象）

                                    有料会員（Miles+会員）では、移動マイルを自動で獲得でき、1日最大10回の移動マイルを獲得できます。各移動マイル獲得では、1回の移動につき、最大50マイルまでは等倍、それ以降は移動距離に対し10%のマイル加算となります。（例：100マイルの移動距離の場合、最初の50マイル+残り50マイルの10% = 獲得移動マイル55マイル）。また、2倍の移動マイルを獲得できるブーストが2回/日、30日間の移動履歴閲覧が可能です。さらに、すべてのマイル交換が15%OFF（一部ギフトカードは除く）、飛行機移動でのマイル獲得、広告表示なしでアプリを利用できます。有料会員は、106,250マイル/月までギフトカードに交換可能です。有料会員として最初の3ヶ月以内に7,500マイルを獲得すると、50,000ボーナスマイルを獲得するチャンスがあります。（2023年11月1日以降の新規入会者が対象）また有料会員はメンテナンスマイル適用が免除されます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 32,
                        "text": "次のステータスに上がる方法は？",
                        "slug": "how-to-move-up-to-the-next-status",
                        "answer_array": [
                            {
                                "id": 71,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    マイル獲得数/月の条件を達成することで、次のステータスに上がることができます。ゴールドになるには500マイル以上、プラチナになるには2,000マイル以上、ダイヤになるには5,000マイル以上獲得する必要があります。
                                    `}
                                    </span></div>
                            }
                        ]
                    }
                ]
            },
            {
                "id": 5,
                "name": "特典について",
                "slug": "rewards",
                "user": "user",
                "image_src": assets.help_page_category_icon_5,
                "question_array": [
                    {
                        "id": 26,
                        "text": "1日に複数枚のギフトカードに交換できないのはなぜですか？",
                        "slug": "why-cant-i-purchase-more-than-one-gift-card-a-day",
                        "answer_array": [
                            {
                                "id": 44,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    ギフトカード特典は人気が高く、ご利用される方が多いため、より多くの方にギフトカード特典の交換機会を提供するために、週に1回のギフトカード交換となるよう制限を設けています。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 27,
                        "text": "マイルの交換先はどのようなものがありますか？",
                        "slug": "how-are-all-the-rewards-being-grouped",
                        "answer_array": [
                            {
                                "id": 44,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    Miles アプリのホームタブにある、「マイルを交換しよう」の下にマイルの交換先カテゴリが表示されています。ギフトカードへの交換、抽選への参加、寄付というカテゴリが存在します。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 28,
                        "text": "特典の仕組みは？",
                        "slug": "how-do-i-purchase-rewards",
                        "answer_array": [
                            {
                                "id": 45,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    移動やボーナスで獲得したマイルを使って、アプリ内の特典と交換することができます。特典と交換するには、特典カードをタップし、画面の下部にある「交換する」ボタンをタップし（十分なマイルを持っている場合）、次の画面で「確認」を押します。すべての特典で同じように動作します。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 29,
                        "text": "一度交換した特典はどうなりますか？",
                        "slug": "what-happens-to-the-rewards-once-purchased",
                        "answer_array": [
                            {
                                "id": 46,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    交換された特典は、「特典」ページの「交換された特典」タブに表示されています。交換した特典をタップすると、その特典の交換確認ページに移動します。ここから、特典を利用することができます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    // {
                    //     "id": 30,
                    //     "text": "Do Lyft Line and Uber Pool qualify for carpool Challenges?",
                    //     "slug": "do-lyft-line-and-uber-pool-qualify-for-carpool-challenges",
                    //     "answer_array": [
                    //         {
                    //             "id": 47,
                    //             "text": <div className="answer"><span><p><span>Yes. Since these are technically carpool trips, they qualify for a carpool Challenge. &nbsp;However, both users must have the Miles app to earn carpool credit. In the near future, we expect to be able to detect Lyft Line/Uber Pool automatically and award Carpool credit even if only one party has the Miles app.</span></p></span></div>
                    //         }
                    //     ]
                    // },
                    {
                        "id": 31,
                        "text": "特典に有効期限はありますか？",
                        "slug": "do-rewards-expire",
                        "answer_array": [
                            {
                                "id": 48,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    はい。特典には有効期限があります。特典ごとに有効期限が設定されています。有効期限が近い特典は、特典ページでハイライト表示され、交換ができる残りの日数が表示されます。ギフトカードなどの一部の特典は、交換に有効期限がありますが、一度交換すればいつでも利用できます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 32,
                        "text": "特典ページに特典が表示されないのはなぜですか？",
                        "slug": "why-don-t-i-see-any-rewards-in-the-rewards-page",
                        "answer_array": [
                            {
                                "id": 49,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    特典ページに特典が表示されていない場合は、ページを更新する必要があるかもしれません。更新するには、ページを下に引っ張るとページを更新できます。

                                    もしくは該当カテゴリで現在提供中の特典を全て交換された場合は、「おめでとうございます。特典を全て入手しました。このカテゴリの特典を全て入手しました」と表示されます。該当カテゴリで新たな特典が追加されるまでお待ちいただくか、別のカテゴリ掲載の特典交換をご検討ください。

                                    なお、交換された特典はアプリホーム画面>左上の人型のアイコン>「交換された特典」から確認が可能です。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 33,
                        "text": "特典の更新頻度は？",
                        "slug": "how-frequently-are-the-rewards-refreshed",
                        "answer_array": [
                            {
                                "id": 50,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    Milesでは、継続的に新しい特典を追加し、利用者から好評だった過去の特典も提供しています。更新の頻度は決まっていません。ご興味ある特典は、数量限定や有効期限付きのものも多いので、お早めに交換いただくことをおすすめします。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 34,
                        "text": "後で交換したい特典をブックマークしたり、保存するには？",
                        "slug": "how-can-i-bookmark-or-save-a-reward-that-i-want-to-purchase-later",
                        "answer_array": [
                            {
                                "id": 51,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    ブックマークや保存された特典は、特典ページの上部に表示され、検討していた特典を簡単に見直すことができます。保存したい特典のハートマークをタップすると、保存できます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 67,
                        "text": "特典を並べ替える方法はありますか？",
                        "slug": "is-there-a-way-to-sort-rewards",
                        "answer_array": [
                            {
                                "id": 64,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    はい。 以下のような基準で特典を並び替えることができます。

                                    近く（距離）
                                    オンライン
                                    有効期限
                                    必要なマイル数
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 66,
                        "text": "カテゴリー別に特典を絞り込む方法はありますか？",
                        "slug": "is-there-a-way-to-filter-rewards-by-categories",
                        "answer_array": [
                            {
                                "id": 63,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    はい。 特典ページでは、画面上部のカテゴリータブをタップすると、以下のカテゴリーごとに分類されたすべての特典が表示されます。

                                    フード・ドリンクオート
                                    日用品
                                    健康・美容
                                    移動・体験
                                    プレゼント用
                                    ジュエリー・アクセサリー
                                    アパレル・シューズ
                                    ペット
                                    ギフトカード
                                    その他
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 78,
                        "text": "特典とは何ですか？",
                        "slug": "how-do-the-rewards-work-2",
                        "answer_array": [
                            {
                                "id": 76,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    特典（マイルの交換先）の種類には以下が含まれます。

                                        1. ギフトカードへの交換
                                        2. 抽選
                                        3. 寄付
                                        4. 通常特典（0マイルで交換可能）
                                    
                                    移動などで獲得したマイルを使用して、アプリ内で特典（マイルの交換先）に交換可能です。Miles アプリのホームタブにある、「マイルを交換しよう」の下のカテゴリから交換先に進むことができます。なお、有料会員（Miles+会員）はマイル交換が15%OFF（一部ギフトカードは除く）となります。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 79,
                        "text": "お友達紹介のギフトカードはどのように受け取れますか？",
                        "slug": "when-do-i-get-my-referral-gift-card",
                        "answer_array": [
                            {
                                "id": 77,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    ギフトカード"は、お友達紹介キャンペーン終了日までに紹介者が登録し、指定された移動の回数・距離を完了した対象会員に付与されます。

                                    ご注意：ご紹介いただく方は、Milesの新規利用者であることが条件となります。
                                    
                                    条件を満たしていれば、プッシュ通知とEメールが送信され、Milesアプリの「特典」タブの「新しく追加された特典」にギフトカードが表示されます。
                                    
                                    特定のキャンペーンで発表された期日までにギフトカードが表示されない場合は、残念ながら1つ以上の理由で対象外となっている可能性があります：
                                    
                                    キャンペーン期間中に、必要最低人数のご紹介者様が登録されなかった。
                                    キャンペーン終了日までに1人以上のご紹介者が指定された移動を完了していない。
                                    紹介者が新規利用者として確認されなかった（以前に別のログイン名でMilesに登録していたり、同じデバイスを使用していた場合など）。
                                    紹介者のリストを表示するには、Milesアプリから「友達紹介」ページにをタップし、右上にある「紹介履歴」を選択してください。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 80,
                        "text": "アンケートに回答してマイルを獲得するにはどうしたらいいですか？",
                        "slug": "how-do-i-earn-miles-from-taking-a-survey",
                        "answer_array": [
                            {
                                "id": 78,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    アンケートに回答してマイルを獲得するには

                                    ・アプリホームタブの「クイズ」アイコンをクリック
                                    ・回答可能なアンケートがある場合、クイズのページ上部に緑色のボタンで参加可能なアンケートがあることが表示されます。
                                    ・緑色のボタンをタップ後、アンケートに回答ください。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 81,
                        "text": "アンケートでマイルが獲得できなかったのはなぜですか？",
                        "slug": "why-didn-t-i-earn-miles-for-my-survey",
                        "answer_array": [
                            {
                                "id": 79,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    アンケートの結果、マイルが獲得できない理由はいくつかあります：

                                    ・アンケートの参加資格を満たしていない、もしくはアンケート対象外の場合
                                    ・アンケート回答を完了する前にアンケートを終了した場合
                                    ・アンケート回答時にVPN使用が検知された場合
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 82,
                        "text": "アンケートの結果、一部のマイルしか獲得できなかったのはなぜですか？",
                        "slug": "why-did-i-only-receive-partial-miles-for-my-survey",
                        "answer_array": [
                            {
                                "id": 80,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    提供した回答がアンケートの要件を満たしていない場合です。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 83,
                        "text": "アンケートのマイルはいつ付与されるのですか？",
                        "slug": "when-are-my-miles-awarded",
                        "answer_array": [
                            {
                                "id": 81,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    マイルはアンケート回答完了後3営業日以内に付与されます。アンケートに回答し、完了後3営業日を過ぎてもマイルが付与されない場合は、アプリホーム画面左上の人型のアイコンをタップし「お問い合わせ」>「問題を報告する」よりご連絡ください。

                                    後日確認させていただく場合があるため、該当アンケート画面のスクリーンショットを撮り保存しておいてください。

                                    なお、マイルが付与されたかどうかの確認はアプリホーム画面のマイルバンクより確認可能です。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 84,
                        "text": "なぜ「アンケート要件マッチせず」と判定されたのですか？",
                        "slug": "why-did-i-get-disqualified-from-the-survey",
                        "answer_array": [
                            {
                                "id": 82,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    初期のアンケート回答より、参加資格を満たしていないと判断された場合、「アンケート要件マッチせず」と判定され、アンケートが早く終わってしまうことがあります。

                                    あなたの回答が、アンケート提供元がターゲットとしている対象者と一致しない場合、あなたの回答は調査結果から除外され、アンケートは終了します。ご了承をお願い申し上げます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 85,
                        "text": "アンケートの回答中にエラーが発生したのはなぜですか？",
                        "slug": "why-did-an-error-occur-while-taking-the-survey",
                        "answer_array": [
                            {
                                "id": 83,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    アンケートの回答中に、エラーや技術的な問題が発生することがあります。これは、インターネット接続の問題やVPNを使用している場合に発生することがあります。このような場合は後日確認させていただく場合があるため、該当アンケート画面のスクリーンショットを撮り保存しておいてください。またアプリホーム画面左上の人型のアイコンをタップし「お問い合わせ」>「問題を報告する」よりご連絡ください。

                                    VPNを使用している場合、アンケート回答で得れる全てのマイルが獲得できないことがありますのでご注意ください。VPNを利用してのアンケート回答はお勧めしません。使用しているネットワーク回線の変更をご検討ください。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 86,
                        "text": "PayPalからの入金が確認できないのはなぜですか？",
                        "slug": "why-cant-i-see-payment-from-paypal",
                        "answer_array": [
                            {
                                "id": 84,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    Miles アプリ上の該当PayPalギフトカードで、お客様のPayPalアカウントを連携手続き後、入金完了まで最大3営業日かかる場合があります。3営業日以上経ってもお客様のPayPalアカウント上で、Miles からの該当入金が確認できない場合は、アプリのホーム > 左上の人型のアイコン > お問い合わせ > 問題を報告する、の手順でお問い合わせください。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 87,
                        "text": "PayPalアカウントを連携できなかった場合、交換に使用したマイルは戻りませんか？",
                        "slug": "unable-to-link-my-paypal-account",
                        "answer_array": [
                            {
                                "id": 85,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    いいえ、PayPalアカウントを連携できなかった場合、PayPalギフトカードへの交換に使用したマイルは、お客様のMiles アカウントに戻されます。交換から30日後にPayPaアカウントが連携されていない場合、交換に使用したマイルはお客様のMiles アカウントに戻されます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 88,
                        "text": "ギフトカードへはどのくらいの頻度で交換できますか？",
                        "slug": "how-often-can-I-redeem-a-gift-card",
                        "answer_array": [
                            {
                                "id": 86,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    無料会員は週に1回、ギフトカードへ交換可能です。かつ、無料会員は62,500マイル/月まで交換可能です。有料会員（Miles+会員）は、106,250マイル/月まで交換可能です。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 89,
                        "text": "寄付はギフトカードと同じ価値がありますか？",
                        "slug": "are-donations-worth-the-same-as-gift-cards",
                        "answer_array": [
                            {
                                "id": 87,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    人気であるギフトカードへの交換機会を優先するため、ギフトカード交換が最良のレートとなるように努めております。しかしながら、寄付団体への寄付をする機会もまだございます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 90,
                        "text": "ゲームをプレイした後、マイルが付与されるまでどのくらいかかりますか？",
                        "slug": "how-long-after-playing-a-game-will-I-receive-my-miles",
                        "answer_array": [
                            {
                                "id": 88,
                                "text": <div className="answer"><span><p><span>マイルを獲得できるタスク目標はゲームによって異なります。なお、該当ゲームの該当タス目標を達成後、マイルが付与されるまでに最大3日かかる場合があります。</span></p></span></div>
                            }
                        ]
                    },
                    {
                        "id": 91,
                        "text": "プレイできるゲームの数に制限はありますか？",
                        "slug": "is-there-a-limit-to-how-many-games-i-can-play",
                        "answer_array": [
                            {
                                "id": 89,
                                "text": <div className="answer"><span><p><span>プレイできるゲーム数に制限はありません。ただし、すべてのゲームにそれぞれのマイルを獲得するためのタスク目標があります。すべてのタスク達成後にプレイされたゲームに対してはマイルは加算されません。</span></p></span></div>
                            }
                        ]
                    },
                    {
                        "id": 92,
                        "text": "ゲームのタスク目標を達成したのにマイルが付与されないのはなぜですか？",
                        "slug": "why-didnt-I-get–credit-for-a-game-that-I-played",
                        "answer_array": [
                            {
                                "id": 90,
                                "text": <div className="answer">
                                    <span>
                                        <p><span>タスク目標を達成した時のマイル付与はゲームによって異なります。タスク目標を達成したにも関わらず該当マイルが付与されなかった場合は、詳細情報と付与されなかったマイルについて、<a href="https://adjoe.zone/legal/en/support_web.html?UserUUID=06e0e675-4dea-441c-9afc-0286bfc24b41&SDKHash=287a3b9fcbca1b366683e5e8c58ac9f8&user_id=2709208&idfa=ED4CC3CC-D35D-403D-B296-DE9F24428572&__platform__=ios">ゲーム提供元</a>にお問い合わせください。
なお、VPNやプライベートブラウズを使用していないことをご確認ください。これらの環境の場合、自動的にマイル付与がされない原因となります。また、そのゲームのプレイを開始する前に、そのゲームが「マイアプリ」セクションに表示されていることをご確認ください。</span></p>
                                        <p><span>*ゲームインストール後、すぐに「マイアプリ」セクションに表示されない場合は、少しだけゲームをプレイした後、「マイアプリ」セクションを確認することをお勧めします。</span></p>
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 93,
                        "text": "どのような種類のゲームをプレイできますか？",
                        "slug": "what-kinds-of-games-can-I-play",
                        "answer_array": [
                            {
                                "id": 91,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    利用可能なゲームは適宜変更される場合があります。利用可能なゲームは、Miles アプリホームのゲームアイコン >「今すぐプレイ」をタップ > ブラウザに遷移された際に表示されますのでご確認ください。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 94,
                        "text": "ゲームでマイルを獲得するためのタスク目標はどこから確認できますか？",
                        "slug": "where-can-I-find-the-goals-for-earning-miles-for-my-game",
                        "answer_array": [
                            {
                                "id": 92,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    (1) Miles アプリホームのゲームアイコンをタップ
                                    (2) 「今すぐプレイ」をタップ
                                    (3) ブラウザに遷移後、「マイアプリ」セクションをタップ
                                    (4) 各ゲームの「もっと」という文字をタップすると、そのゲームでのタスク目標とタスクを完了した際に付与されるマイル数をご確認いただけます。
                                    
                                    なお、初めてプレイする際には、上記(2)の後ブラウザに遷移後、「カタログ」セクションから各ゲームをインストールすると、そのゲームは以後「マイアプリ」セクションに表示されます。ゲームプレイでマイルが付与されるためには「マイアプリ」セクションに該当のゲームが表示されているかご確認ください。
                                    `}
                                    </span></div>
                            }
                        ]
                    }
                ]
            },
            {
                "id": 6,
                "name": "移動・マイルについて",
                "slug": "trips-miles",
                "user": "user",
                "image_src": assets.help_page_category_icon_6,
                "question_array": [
                    {
                        "id": 35,
                        "text": "マイルはどのようにしてたまるのですか？",
                        "slug": "how-do-i-earn-miles",
                        "answer_array": [
                            {
                                "id": 15,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    マイルは、移動、ブースト、移動目的タグ、アンケート、クイズ、特別
                                    コード、各ステータスの月間ボーナス、友達紹介を通じて獲得できます。移動については、無料会員では、移動マイルを手動で獲得する必要があり、1日最大5回の移動マイルを獲得できます。各移動マイル獲得では、1回の移動につき、最大10マイルまでは等倍、それ以降は移動距離に対し10%のマイル加算となります。（例：100マイルの移動距離の場合、最初の10マイル+残り90マイルの10% = 獲得移動マイル19マイル）。有料会員（Miles+会員）では、移動マイルを自動で獲得でき、1日最大10回の移動マイルを獲得できます。各移動マイル獲得では、1回の移動につき、最大50マイルまでは等倍、それ以降は移動距離に対し10%のマイル加算となります。（例：100マイルの移動距離の場合、最初の50マイル+残り50マイルの10% = 獲得移動マイル55マイル）。

                                    なお、下記のすべての移動手段でマイルを獲得できます。

                                    ・車
                                    ・電車
                                    ・バス
                                    ・徒歩
                                    ・ランニング
                                    ・自転車
                                    ・飛行機*

                                    *飛行機移動でのマイル獲得は有料会員（Miles+会員）のみ有効です。



                                    アプリをインストールして設定すると、これらの移動手段での移動は自動的に判定され、有効な移動ごとにマイルが付与されます。（*無料会員の場合は、移動マイルは手動獲得必要）
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 36,
                        "text": "マイルや移動を登録する必要はありますか？",
                        "slug": "do-i-need-to-log-my-miles-or-trips",
                        "answer_array": [
                            {
                                "id": 16,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    いいえ、マイルを登録する必要はありません。アプリをインストールして、位置情報の取得を”常に許可”に設定いただくと、すべての移動のマイルが自動的に記録され、加算されます。移動前から移動後まで、アプリを開く必要はありません。

                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 37,
                        "text": "他人の車に同乗して移動しているときに、自分のアプリが起動していたらマイルはたまりますか？",
                        "slug": "what-if-i-m-traveling-as-a-passenger-in-someone-else-s-car-and-my-app-is-running",
                        "answer_array": [
                            {
                                "id": 17,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    はい、マイルがたまります。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 38,
                        "text": "移動やマイルが正しく反映されていません。確認すべきことはありますか？",
                        "slug": "why-do-i-have-a-missing-trip-and-missing-miles-how-can-i-report-it",
                        "answer_array": [
                            {
                                "id": 18,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    移動したにも関わらず、その移動やマイルが自動的に記録されていない場合があります。このような事象が起こる原因として、以下が考えられます。


                                    移動履歴の反映には最大で48時間ほどお時間を要する場合もあります。また反映されなかった移動については数日後反映されることも稀にありますが、システム上のデータ不足などの理由により反映されないままになる場合もあることをご了承ください。
                                    
                                    
                                    次に多くあるのは、電波が届く場所での移動ではなかった、もしくは通常のスマートフォンのご利用には問題ない電波状況であってもシステム上移動検知するには移動時の電波が弱かったことが、特に短距離（特に1マイル（約1.6km））の移動履歴が正しく反映されない原因として考えられます。また、周回などの移動は、正確なデータが得られず反映されないことがあります。
                                    
                                    またOS毎に、下記の位置情報などの設定をご確認お願いいたします。
                                    
                                    【iOS端末の場合】
                                    (1)  iOS端末の設定>設定の中を下の方にスクロール>端末に入っているアプリが並んだ箇所の中からMiles アプリをタップ>位置情報をタップ>「常に」にチェック>あわせてその真下にある「正確な位置情報」の項目も「ON」に変更
                                    (2) iOS端末の設定>Miles アプリ>Appのバックグラウンド更新を「ON」に変更

                                    ※Appのバックグラウンド更新箇所がグレーアウトしている場合は、iOS端末設定>一般>Appのバックグラウンド更新を「ON」に変更。その後、iOS端末の設定>Miles アプリ>Appのバックグラウンド更新がグレーアウトしておらず「ON」になっているか確認してください。なお、ご利用iOS端末が低電力モードのときは、Appのバックグラウンド更新が無効になりますのでご留意ください。

                                    【Android端末の場合】
                                    (1) 端末の設定>アプリと通知>Miles アプリをタップ> 権限>位置情報>「常に許可」に変更
                                    (2) 端末の設定>アプリと通知>Miles アプリをタップ> モバイルデータとWi-Fi>「バックグラウンドデータ（バックグラウンドデータの使用を有効にする）」を「ON」に変更（もしくは、アプリと通知>Milesアプリ>データ使用量>バックグラウンドデータ（バックグラウンドでのモバイルデータの使用を有効にする）を「ON」に変更）
                                    (3) 端末の設定>位置情報>詳細設定>Google位置情報の精度（もしくは「設定>セキュリティと現在地情報>詳細設定>位置情報>詳細設定」など）を「ON」に変更

                                    両OSともに位置情報サービスを「常にON」もしくは「常に許可」に設定してください。「なし」または「このAppの使用中のみ許可」を選択すると、アプリが正常に機能しなくなります。
                                    また移動時、常にアプリを開いていただく必要はございませんが、携帯端末は持ち歩いていただく必要がございます。（スマートウォッチや他のヘルスケアアプリなどと連携はできませんのでご理解ください）
                                    なお、設定がされている状態でアプリにログインしたままでないとマイルはたまりませんため、アプリにはログインしたままでお願いいたします。

                                    上記設定をされた上で、下記もあわせてお試しください。

                                    【iOS/Android共通: その他の履歴反映に繋がる可能性のある操作】
                                    (1)今後最低でも週に2回はアプリを立ち上げていただく（アプリが起動することでアプリが更新されます）
                                    (2) アプリのアップデートを確認できるようでしたら、最新版にアップデート
                                    (3) 他の位置情報を利用したアプリでは位置情報が動作しているのかご確認

                                    ※なお、Miles アプリはインターネットに接続されていない状態でも動作します。未接続のオフラインで使用する場合、少なくとも24時間に1回インターネット（Wi-Fiまたはデータプラン）に接続することで、過去24時間の移動のマイルが獲得できます。この場合、位置情報が常にONであることが必須条件となりますので、ご留意ください。

                                    また、アプリ内では有効な移動のみが表示され、マイルが付与されます。有効な移動として判定される条件は以下の通りです。

                                    自動車 - 5分以上の休憩なしで0.5マイル（約800メートル）以上の距離を移動すること。
                                    相乗り - 5分以上の休憩なしで0.5マイル（約800メートル）以上の距離を移動すること。相乗りを自動的に検出するためには、利用者に加えて、Milesをインストールして動作している利用者がもう一人同乗している必要があります。
                                    電車 - 5分以上の休憩なしで0.5マイル（約800メートル）以上の距離を移動すること。
                                    バス - 5分以上の休憩なしで0.5マイル（約800メートル）以上の距離を移動すること。
                                    ボート - 5分以上の休憩なしで0.5マイル（約800メートル）以上の距離を移動すること。
                                    徒歩/ランニング - 2分以上の休憩なしで0.2マイル（約320メートル）以上の距離を移動すること。屋外での徒歩およびランニングのみ。
                                    自転車* - 5分以上の休憩なしで0.5マイル（約800メートル）以上の距離を移動すること。
                                    航空 - 短距離および長距離のフライト。

                                    * 自転車には電動スクーターは含まれていません。電動スクーターの自動検出機能の追加は現在検討中です。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 39,
                        "text": "なぜ移動が部分的しか表示されないことがあるのですか？",
                        "slug": "why-only-partial-walk-trips-show-up",
                        "answer_array": [
                            {
                                "id": 19,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    部分的な移動がアプリに表示される主な理由は、特に短距離（1マイル（1.6キロ）未満）の移動の際に、スマートフォンの電波の状況が悪いことです。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 40,
                        "text": "1回の移動で何マイルたまりますか？",
                        "slug": "how-many-miles-are-earned-for-each-trip",
                        "answer_array": [
                            {
                                "id": 20,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    各移動の移動距離で獲得できるマイルは、以下の式で計算されます：
                                    
                                    無料会員の場合 - 1回の移動につき、最大10マイルまでは等倍、それ以降は移動距離に対し10%のマイル加算
                                    有料会員（Miles+会員） - 1回の移動につき、最大50マイルまでは等倍、それ以降は移動距離に対し10%のマイル加算

                                    移動で獲得できるマイルには、移動手段に応じて乗数が適用される場合があります。バス、電車の移動では2倍のエコボーナスが獲得可能です。徒歩、ランニング、自転車の移動では3倍の健康ボーナスが獲得可能です。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 41,
                        "text": "獲得できるボーナスマイルにはどのようなものがありますか？",
                        "slug": "what-are-all-the-bonus-miles-i-can-earn",
                        "answer_array": [
                            {
                                "id": 21,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    ボーナスマイルの獲得方法は、利用者のステータス、ご利用いただいた移動手段、お友達の紹介の3つがあります。

                                    移動手段毎に獲得できるボーナスマイル：移動手段毎に獲得できるボーナスマイルは、アプリの「移動履歴」ページで、左上にある「i」ボタンをタップしてください。
                                    
                                    各会員ステータスによるボーナスマイルの獲得：各会員に応じて、毎月ボーナスマイルを獲得できます。詳しくは、アプリ内「ホームタブ」より、右上にあるご自身の会員ステータス（シルバー／ゴールド／プラチナ／ダイヤ）をタップし、次のページの右上にある各ステータスの色が並んだマークをタップしてご確認ください。
                                    
                                    お友達紹介によるボーナスマイル：お友達を紹介して、一定条件をクリアすると、ボーナスマイルが入手できます。詳しくは、「友達紹介」タブの詳細をご確認ください。　※キャンペーンとして、ボーナスマイルではなくアマゾンギフト券などを入手できる場合がございます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    // {
                    //     "id": 42,
                    //     "text": "What do I need to do to earn bonus miles for ride hailing services like Uber/Lyft?",
                    //     "slug": "what-do-i-need-to-do-to-earn-bonus-miles-for-ride-hailing-services-like-uber-lyft",
                    //     "answer_array": [
                    //         {
                    //             "id": 22,
                    //             "text": <div className="answer"><span><p><span>To earn bonus miles for ride hailing services like Uber/Lyft, connect your Miles app with them. To connect, go to the Account page, click on ‘Connectors’ and select the apps/services you’d like to connect with and earn bonus miles. </span></p></span></div>
                    //         }
                    //     ]
                    // },
                    {
                        "id": 43,
                        "text": " 獲得・利用マイルの履歴を見ることはできますか？",
                        "slug": "can-i-see-a-history-of-my-miles-earned-and-redeemed",
                        "answer_array": [
                            {
                                "id": 23,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    はい。獲得したマイル（ボーナスマイルを含む）と月ごとに交換したマイルの履歴を見るには、Milesアプリを開いてホームタブに移動し、「マイルバンク」までスクロールしてください。「すべて表示」を選択すると、当月と前月の「マイルバンク」履歴を閲覧できます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    // {
                    //     "id": 44,
                    //     "text": "相乗りでボーナスマイルはたまりますか？",
                    //     "slug": "can-i-earn-bonus-miles-for-carpooling",
                    //     "answer_array": [
                    //         {
                    //             "id": 24,
                    //             "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                    //                 {`
                    //                 ※相乗りの機能（検出）は現在改修しております。ご不便をおかけし申し訳ございませんが、ご了承ください。相乗りについては今後対応を検討してまいります。


                    //                 Milesは、利用者がMilesアプリを使って他の利用者と相乗りしていることを自動的に検出します。相乗りしている方がまだMilesアプリをダウンロードしていない場合は、相乗りボーナスマイルを獲得するために、相乗りしている方にMilesアプリのダウンロードをお願いしてください。ボーナスマイルは、利用者が他の方と相乗りしていた移動のマイル数に対してのみ付与されます。例えば、10マイルの自動車移動の場合、40%の4マイルのみを相乗りした場合、4マイルの移動に対してのみボーナスマイルが付与されます。 さらに、市や交通機関が相乗りに対して、追加の特典を提供する場合もあります。
                    //                 `}
                    //                 </span></div>
                    //         }
                    //     ]
                    // },
                    {
                        "id": 45,
                        "text": "移動は登録する必要ありますか？",
                        "slug": "do-i-have-to-log-the-trips",
                        "answer_array": [
                            {
                                "id": 25,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    いいえ、移動の記録は、スマートフォンでMilesアプリでの位置情報取得を「常に許可」に設定しておくことで、自動的に行われます。移動前から移動後まで、アプリを開く必要はありません。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 46,
                        "text": "移動がアプリ内に表示されるまで、どれくらい時間がかかりますか？",
                        "slug": "how-long-before-my-trip-appears",
                        "answer_array": [
                            {
                                "id": 26,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    通常、移動が完了してからアプリに移動が表示され、マイルが加算されるまで10分程度かかります。通常のスマートフォンのご利用には問題ない電波状況であっても、移動を検知するには電波状況が芳しくない、WiFiネットワークの状態が悪いまたは遅い、バッテリー残量が少ないなどの理由で、システムが移動を処理するのにそれ以上かかる場合があり、その結果、移動がアプリに表示されるまでに60分以上かかることがあります。また、サーバーの影響で一時的に反映まで数日かかる場合があります。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 47,
                        "text": "過去のすべての移動履歴を見ることはできますか？",
                        "slug": "can-i-see-all-my-past-trip-details",
                        "answer_array": [
                            {
                                "id": 27,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    アプリの「移動履歴」ページでは、過去のすべての移動履歴が表示されます。移動履歴は、当月のトリップのみが表示されます。前月以前の移動を表示するには、同ページの上部中央にある月をタップして、希望の月を選択します。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 48,
                        "text": "過去の移動を、移動手段別にまとめて見ることはできますか？",
                        "slug": "can-i-see-all-my-trips-grouped-by-mode",
                        "answer_array": [
                            {
                                "id": 28,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    はい。 特定の移動手段とまとめて見るには、「移動履歴」ページの左上にあるメニューボタンをタップし、希望する移動手段を選択します。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 49,
                        "text": "なぜ移動手段が誤って判別されたのですか？",
                        "slug": "why-was-my-trip-misclassified",
                        "answer_array": [
                            {
                                "id": 29,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    移動したにも関わらず、その移動やマイルが自動的に記録されていない場合があります。以下のような理由が考えられます。

                                    携帯電波が弱い・届かない：これが最も一般的な理由です。
                                    
                                    位置情報サービス：iOSデバイスの場合、位置情報サービスを「常に許可」に設定してください。「なし」または「このAppの使用中のみ許可」を選択すると、アプリが正常に機能しなくなります。位置情報サービスを設定するには、ホーム画面より「設定」→「プライバシー」→「位置情報サービス（オン）」→「Miles」を選択してください。iOSでは、「バックグランドでの位置情報の使用を許可したままにしますか?」 と表示されることがありますが、「常に許可する」を選択するとアプリが正常に動作します。
                                    Android端末では、位置情報サービスを「許可」に設定してください。位置情報サービスは、「設定」→「アプリケーション」→「Miles」→「アクセス許可」→「位置情報」から「オン」にすることで更新できます。
                                    
                                    アプリのバージョン：当社は、新機能の追加、バグの修正、体験やパフォーマンスの向上のために、アプリのアップデートを定期的に公開しています。アプリを最新の状態にしておくことをおすすめします。
                                    
                                    OSのバージョン：アプリが正常に機能するために、利用可能な場合は最新のOSバージョンにアップデートしてください。
                                    
                                    インターネット接続環境：Milesは、インターネットに接続されていないオフライン手段でも動作します。オフライン手段で使用する場合、少なくとも24時間に1回インターネット（Wi-Fiまたはデータプラン）に接続することで、過去24時間の移動のマイルが獲得できます。
                                    
                                    また、アプリ内では有効な移動のみが表示され、マイルを獲得することができます。有効なトリップの基準：
                                    
                                    車 - 5分以上の休憩なしで0.5マイル以上の距離を移動する。
                                    相乗り - 5分以上の休憩なしに0.5マイル以上の距離を移動すること。相乗り移動を自動的に検出するには、利用者と相乗りするもう一人のユーザーの少なくとも一人が、Milesアプリを携帯電話にインストールして動作させている必要があります。
                                    電車 - 最短距離0.5マイル、5分以上の休憩なし。
                                    バス - 5分以上の休憩がない0.5マイル以上の距離。
                                    ボート - 5分以上の休憩を挟まずに0.5マイル以上の距離を移動することができます。
                                    ウォーク/ラン - 2分以上の休憩なしで0.2マイル以上の距離を移動すること。屋外でのウォーキングおよびランニングのみ。
                                    自転車* - 5分以上の休憩なしで0.5マイル以上の距離を移動すること。
                                    航空 - 短距離および長距離のフライト。
                                    * Bikeにはe-scootersが含まれていません。e-scootersの自動検出機能の追加を検討しています。
                                    
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 50,
                        "text": "誤って判定された移動を更新するにはどうすればいいですか？",
                        "slug": "how-can-i-update-a-misclassified-trip",
                        "answer_array": [
                            {
                                "id": 30,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    誤って判定された移動を更新するには、「移動履歴」ページで関連する移動を探します。移動履歴の左上にあるアイコンをタップし、希望する手段を選択します。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 51,
                        "text": "移動手段の変更リクエストはどのくらいの時間で処理されますか？",
                        "slug": "in-how-much-time-are-trip-mode-change-requests-processed",
                        "answer_array": [
                            {
                                "id": 31,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    移動手段の変更リクエストは、当社のデータに基づいて合理的だと判断された場合、即座に承認されます。それ以外の場合、お時間を要する場合もありますことを予めご了承ください。リクエスト受理が完了しますと、プッシュ通知でお知らせします。なお、移動手段変更をリクエストをしていただいても受理されない場合もございます。また、受理されなかった場合は、モード変更依頼中のステイタスのままになる場合もありますことを、ご理解ください。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 52,
                        "text": "位置情報サービスをオンにしなくても、Milesアプリを利用できますか？",
                        "slug": "can-i-use-the-miles-app-without-turning-on-location-services",
                        "answer_array": [
                            {
                                "id": 32,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    Milesは、利用者のすべての移動に対して自動的にマイルを付与するために、位置情報サービスを必要とします。利用者の位置情報が第三者と共有されることはありません。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 53,
                        "text": "獲得したマイルは四捨五入されますか？",
                        "slug": "are-the-miles-earned-rounded-off",
                        "answer_array": [
                            {
                                "id": 33,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    はい、月間獲得マイル数の合計は、最も近い整数値に切り捨てられます。各移動で獲得したマイルは切り捨てられません。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 54,
                        "text": "マイルに有効期限はありますか？",
                        "slug": "do-miles-expire",
                        "answer_array": [
                            {
                                "id": 34,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    もし60日ごとに少なくとも一度はMiles アカウントにログイン、もしくはアプリを起動した場合、あなたのマイル残高はそのまま保持されます。しかし、より快適なユーザー体験を維持し続けるために、2ヶ月以上Miles アプリを開いていない場合には、メンテナンスマイルが差し引かれます。このメンテナンスマイルが適用されるとアカウントのマイル残高は0になります。なお、Miles アプリは無料でご利用可能です。無料会員様の場合、マイル残高からメンテナンスマイルによりマイルが差し引かれるだけで、金銭的な請求はありませんのでご安心ください。
                                    
                                    なお、メンテナンスマイルが発生することを知らなかった、もしくはその他やむを得ない事情により、差し引かれたマイルのお戻しをご希望の場合は、メンテナンスマイルが差し引かれた日から30日以内に、「アプリのホーム > 左上の人型のアイコン > お問い合わせ > 問題を報告する 」の手順でご連絡ください。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    // {
                    //     "id": 55,
                    //     "text": "トリップカードの相乗りアイコンの周りに、％の数字が表示されるのはなぜですか？",
                    //     "slug": "why-do-i-see-a-certain-number-around-the-carpool-icon-in-the-trip-card",
                    //     "answer_array": [
                    //         {
                    //             "id": 35,
                    //             "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                    //                 {`
                    //                 ※相乗りの機能（検出）は現在改修しております。ご不便をおかけし申し訳ございませんが、ご了承ください。相乗りについては今後対応を検討してまいります。


                    //                 %の数字は、他のMilesアプリユーザーと相乗りしていた移動の割合を示しています。
                    //                 `}
                    //                 </span></div>
                    //         }
                    //     ]
                    // },
                    // {
                    //     "id": 56,
                    //     "text": "Why do I see a certain % number around the ride hailing app icon in the trip card?",
                    //     "slug": "why-do-i-see-a-certain-number-around-the-ride-hailing-app-icon-in-the-trip-card",
                    //     "answer_array": [
                    //         {
                    //             "id": 36,
                    //             "text": <div className="answer"><span><p><span>
                    //                 The % number indicates the portion of the trip you were carpooling with another Miles app user(s) while using a ride hailing service.
                    //                 </span></p></span></div>
                    //         }
                    //     ]
                    // },
                    {
                        "id": 57,
                        "text": "移動を削除するにはどうすればいいですか？",
                        "slug": "how-can-i-delete-a-trip",
                        "answer_array": [
                            {
                                "id": 37,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    現在のところ、移動を削除することはできません。近日中にこの機能を追加する予定です。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 59,
                        "text": "ステータスのルールとメリットは何ですか？",
                        "slug": "what-are-the-rules-benefits-of-all-the-tier-status",
                        "answer_array": [
                            {
                                "id": 39,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    各会員ステータスには、達成するための条件が定まっており、毎月のボーナスマイル、特典への交換レート、特定の特典への早期アクセスなど、ステータスによってメリットが異なります。詳しくは、アプリ内「ホームタブ」より、右上にあるご自身の会員ステータス（シルバー／ゴールド／プラチナ／ダイヤ）をタップし、次のページの右上にある各ステータスの色が並んだマークをタップしてご確認ください。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 60,
                        "text": "移動がが2つ以上に分かれているのはなぜですか？",
                        "slug": "why-do-i-see-my-trip-split-into-two-or-more",
                        "answer_array": [
                            {
                                "id": 40,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    移動中に静止している（移動していない）期間がある場合、1つの移動が2つ以上の移動に分割されることがあります。この静止期間は移動手段ごとに異なります。移動手段ごとに、移動が分割されないために静止していられる期間の最大値は以下の通りです：

                                    自動車：5分
                                    相乗り：5分
                                    バス：5分
                                    電車：5分
                                    徒歩/ランニング：2分
                                    自転車：5分
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    // {
                    //     "id": 61,
                    //     "text": "相乗りの移動はどのように検出され、判定されるのですか？",
                    //     "slug": "how-are-carpool-trips-detected-and-classified",
                    //     "answer_array": [
                    //         {
                    //             "id": 41,
                    //             "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                    //                 {`
                    //                 ※相乗りの機能（検出）は現在改修しております。ご不便をおかけし申し訳ございませんが、ご了承ください。相乗りについては今後対応を検討してまいります。


                    //                 相乗りを自動的に検出するためには、利用者に加えて相乗りをするもう一人のユーザーが、スマートフォンにMilesアプリをインストールして動作させている必要があります。
                    //                 `}
                    //                 </span></div>
                    //         }
                    //     ]
                    // },
                    // {
                    //     "id": 62,
                    //     "text": "アプリを利用している他のユーザーとの相乗りが検出されなかったのはなぜですか？",
                    //     "slug": "why-wasn-t-my-carpool-trip-with-another-user-who-uses-the-app-detected",
                    //     "answer_array": [
                    //         {
                    //             "id": 42,
                    //             "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                    //                 {`
                    //                 ※相乗りの機能（検出）は現在改修しております。ご不便をおかけし申し訳ございませんが、ご了承ください。相乗りについては今後対応を検討してまいります。


                    //                 相乗り移動を自動的に検出するためには、利用者と相乗りするもう一人のユーザーが、スマートフォンにMilesアプリをインストールして動作させている必要があります。また、利用者と相乗りするユーザーが以下の条件を満たしている必要があります。
                                    
                    //                 ・位置情報サービス
                    //                 iOS端末の場合：位置情報サービスを「常に許可」に設定してください。「なし」または「このAppの使用中のみ許可」を選択すると、アプリが正常に機能しなくなります。利用者の位置情報が第三者と共有されることはありません。位置情報サービスを設定するには、お使いのスマートフォンの設定 → プライバシー → 位置情報サービス（オン） → Milesで「常に許可」とします。iOSでは、時々「バックグラウンドでの位置情報の使用を許可したままにしますか？」と表示されますが、「常に許可する」を選択してください。
                                    
                    //                 Android端末の場合：位置情報サービスを「常に許可」に設定してください。位置情報サービスはお使いのスマートフォンで、「設定」→「アプリ管理」→「Miles」→「アプリの権限」→「位置情報：常に許可」で更新できます。
                                    
                    //                 ・ログインしたままにする：意図せずにアプリからログアウトしていないかご確認ください。
                                    
                    //                 ・アプリのバージョン：当社は、新機能の追加、問題の修正、エクスペリエンスやパフォーマンスの向上のために、アプリのアップデートを定期的にリリースしています。アプリをアップデートして、最高の体験をしてください。
                                    
                    //                 ・OSのバージョン：アプリの機能を確実にサポートするために、可能な限り最新のOSバージョンにアップデートしてください。
                                    
                    //                 ・インターネット接続環境：このアプリは、インターネットに接続されていないオフライン手段でも完全に動作します。オフライン手段で使用する場合、24時間の間にオフライン手段で行ったすべての移動のマイルを獲得するために、少なくとも24時間に1回はインターネット（Wi-Fiまたはデータプラン）に接続する必要がある場合があります。
                    //                 `}
                    //                 </span></div>
                    //         }
                    //     ]
                    // },
                    // {
                    //     "id": 63,
                    //     "text": "獲得したマイルには換金性がありますか？",
                    //     "slug": "do-the-miles-earned-have-a-cash-value",
                    //     "answer_array": [
                    //         {
                    //             "id": 43,
                    //             "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                    //                 {`
                    //                 獲得したマイルには現金価値はありません。獲得したマイルは、アプリ内で提供される特典のご購入にのみご利用いただけます。
                    //                 `}
                    //                 </span></div>
                    //         }
                    //     ]
                    // },
                    {
                        "id": 75,
                        "text": "「友達紹介」の仕組みについて教えてください。",
                        "slug": "how-does-refer-a-friend-work",
                        "answer_array": [
                            {
                                "id": 72,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    アプリ内「友達紹介」ページに保時されている、利用者独自の招待コードを使って、お友達を招待できます。招待されたお友達は、Milesアプリをインストール後、その招待コードをアプリ内で入力し、一定期間に一定距離の移動を複数回すると、アマゾンギフト券やボーナスマイルが入手できます（条件や特典に関しては、「友達紹介」ページの詳細をご確認ください）。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 76,
                        "text": "友達を紹介しました。なぜボーナスマイルがもらえないのですか？",
                        "slug": "i-referred-a-friend-why-havent-i-received-my-bonus-referral-miles",
                        "answer_array": [
                            {
                                "id": 73,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    お友達を紹介してボーナスマイルを受け取るためには、お友達がMilesアプリに登録し、一定期間に一定距離を複数回移動する必要があります。詳しい条件に関しては、アプリ内「友達紹介」ページをご確認ください。

                                    また、紹介したお友達が新しいアカウントと新しいデバイスを使ってサインアップして、条件をクリアした場合のみ、紹介ボーナスマイルを受け取ることができます。
                                    
                                    なお、お友達紹介のキャンペーンとして、アマゾンギフト券などをプレゼントしている場合は、ボーナスマイルは付与されない場合がございます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 77,
                        "text": "マイルはどのようにしてためるのですか？",
                        "slug": "how-do-i-earn-miles-2",
                        "answer_array": [
                            {
                                "id": 74,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    日々の移動でマイルがたまります。また、環境にやさしい移動では、さらにボーナスマイルが加算されます。アプリをインストールして初期設定をすると（位置情報の許可が「常に許可」に設定されていることを確認してください）、移動が自動的に検出され、移動完了後数日以内にマイルが付与されます。マイルを獲得できる移動手段には、自動車、徒歩、ランニング、自転車、電車、バス、船、スキー、飛行機があります。なお、マイルを獲得するためには、一定の最低距離（通常は移動手段に応じて0.5マイル（約800メートル）以下）を移動する必要があります。

                                    また、以下の方法でもマイルが加算されます。
                                    
                                    ステータス： 各会員ステータスに応じて、毎月ボーナスマイルを獲得できます。
                                    お友達の紹介：利用者専用の紹介コードを使ってお友達をMilesに紹介し、お友達がMilesに入会するとボーナスマイルが加算されます。（キャンペーンによっては、代わりにアマゾンギフト券になる場合ございます）
                                    マイルストーンとアニバーサリー：マイルストーン（例：利用者のお誕生日やMiles入会の記念日）に応じてボーナスマイルを獲得できます。
                                    
                                    `}
                                    <span>お誕生日ボーナスについては
                                        <a 
                                            style={{ textDecoration: "none", color: "#10af42" }}
                                            href="https://www.getmiles.com/jp/help/category/trips-miles/what-are-all-the-bonus-miles-i-can-earn"
                                        >こちら</a>
                                        のFAQをご参照ください。
                                    </span>
                                    </span>
                                    </div>
                            }
                        ]
                    },
                    {
                        "id": 78,
                        "text": "お友達紹介キャンペーンでのボーナスマイルはどのように受け取れますか？",
                        "slug": "how-do-i-get-credit-for-a-referral",
                        "answer_array": [
                            {
                                "id": 75,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    紹介したお友達がアカウントを新規作成する際、お友達は紹介者様から受け取った招待コードをアカウントにご入力が必要です。コード入力はMiles アプリのホームタブをタップし、左上にあるプロフィールアイコンをタップしてください。そうすると「招待/特別コード」という箇所がありますので、そちらをタップし、コードを入力してください。

                                    ご友人が新規ユーザーとして承認されると、紹介者様はボーナスマイルを獲得できるようになります。ボーナスマイルはMilesアプリの「友達紹介」タブの右上の紹介履歴ページ内に表示されます。なお、承認には約3日ほど、場合によってはそれ以上かかりますことを、あらかじめご了承ください。                                    
                                    `}
                                    </span></div>
                            }
                        ]
                    }
                ]
            }
        ],
    }
}.response;


export type ITEM_TYPE = typeof RAW_DATA.user[0];
export type QUESTION_TYPE = typeof RAW_DATA.user[0]['question_array'][0];
